import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
 
export default function Protected(props) {
    const navigate = useNavigate();
    const { Component } = props;
    useEffect(() => {
        let login = localStorage.getItem("loginToken");
        let userName = localStorage.getItem("userName");
        if(!login || !userName){
            navigate("/", {replace: true});
        }
    }, []);
 
    return(
        <Component />
    );
}