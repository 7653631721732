import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import ViewContact from "./components/ViewContact";
import BulkUpload from "./components/BulkUpload";
import ContactDetails from "./components/ContactDetails";
import AddContact from "./components/AddContact";
import AccountFilters from "./components/AccountFilters";
import Protected from "./components/Protected";
import UploadEmailStatus from "./components/uploadEmailStatus";
import RevenueEmployee from "./components/RevenueEmployee";
import DocumentBulkUpload from "./components/DocumentBulkUpload";
import axios from 'axios';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const cancelButtonRef = useRef(null);
  const [ip, setIP] = useState("");
  const [action, setAction] = useState(0);

  const timeInSeconds = 5 * 60 + 0;

  const checkForInactivity = () => {
    const expireTime = localStorage.getItem("expireTime");
   
    if (expireTime < Date.now()) {
      setShowPopup(true);
    }
  };

  
  const updateExpireTime = () => {
    setShowPopup(false);
    const expireTime = Date.now() + 600000;
    localStorage.setItem("expireTime", expireTime);
  };


  useEffect(() => {
    updateExpireTime();

    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const loginToken = localStorage.getItem("loginToken");
      if (loginToken) {
        checkForInactivity();
      }
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    getData();
  }, [ip]);

  const getData = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      setIP(res.data.ip);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error appropriately
    }
  };

  const checkOutUser = () => {
    let userName = localStorage.getItem("userName");
    const formData = {
      ip: ip,
      userName: userName,
      action: action,
    };
    
    axios
      .post("https://dbms.buzz-nation.com/phpApi/insertUserLogData.php", formData)
      .then((response) => {
        localStorage.setItem("login", "");
        localStorage.setItem("loginToken", "");
        localStorage.setItem("userName", '');
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/dashboard"
          element={<Protected Component={Dashboard} />}
        />
        <Route
          exact
          path="/view_all_contact"
          element={<Protected Component={ViewContact} />}
        />
        <Route
          exact
          path="/bulk_upload"
          element={<Protected Component={BulkUpload} />}
        />
        <Route
          exact
          path="/add_contact"
          element={<Protected Component={AddContact} />}
        />
        <Route
          exact
          path="/filters"
          element={<Protected Component={AccountFilters} />}
        />
        <Route
          path="/details/:page/:sourceId"
          element={<Protected Component={ContactDetails} />}
        />
        <Route
          exact
          path="/upload_email_status"
          element={<Protected Component={UploadEmailStatus} />}
        />
        <Route
          exact
          path="/revenue_employee_data"
          element={<Protected Component={RevenueEmployee} />}
        />
        <Route
          exact
          path="/document_bulk_upload"
          element={<Protected Component={DocumentBulkUpload} />}
        />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
      {/* Model */}
      <Transition.Root show={showPopup} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setShowPopup}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="flex justify-center font-bold text-2xl">
                    <CountdownCircleTimer
                          isPlaying
                          duration={timeInSeconds}
                          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                          colorsTime={[50, 40, 20, 0]}
                          size={100}
                          strokeWidth={8}
                          trailColor="#e0e0e0"
                          onComplete={checkOutUser}
                        >
                          {({ remainingTime }) => formatTime(remainingTime)}
                        </CountdownCircleTimer>
                    </div>
                    <div className="mt-4">
                      <p className="text-md text-gray-900 text-center font-semibold">
                        You're being timed out due to inactivity.
                      </p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </BrowserRouter>
  );
}

export default App;
