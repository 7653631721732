import React from "react";
import { ImSpinner8 } from "react-icons/im";
import "./SpinningIcon.css"; // Import your CSS file for styling

function SpinningIcon() {
  return (
    <div className="icon-container">
      <ImSpinner8  fontSize="5em"  color="#16a34a" className="spinning" />
    </div>
  );
}

export default SpinningIcon;
