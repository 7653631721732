import buzzLogoSideBar from "../images/sideBarBuzzLogo.png";
import React, { useState, useEffect } from "react";
import { AiFillHome, AiFillSetting } from "react-icons/ai";
import { RiContactsBook2Fill } from "react-icons/ri";
import { HiDocumentReport } from "react-icons/hi";
import { TbLogout } from "react-icons/tb";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LuContact2 } from "react-icons/lu";
import { FaFileUpload } from "react-icons/fa";
import { HiFilter } from "react-icons/hi";
import { MdMarkEmailUnread } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { IoDocuments } from "react-icons/io5";
import Tooltip from '@mui/material/Tooltip';
import axios from "axios";

function Sidebar() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeLink, setActiveLink] = useState("Dashboard");
  const location = useLocation();
  const navigate = useNavigate();
  const [ip, setIP] = useState("");
  const [action, setAction] = useState(0);

  useEffect(() => {
    getData();
  }, [ip]);

  const getData = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      setIP(res.data.ip);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error appropriately
    }
  };

  const checkOutUser = () => {
    handleLinkClick("/logout");
    if(ip == ''){
      getData();
    }
    let userName = localStorage.getItem("userName");
    const formData = {
      ip: ip,
      userName: userName,
      action: action,
    };
    axios
      .post("https://dbms.buzz-nation.com/phpApi/insertUserLogData.php", formData)
      .then((response) => {
        localStorage.setItem("login", "");
        localStorage.setItem("loginToken", "");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };

  // function logoutSubmit() {
  //   checkOutUser();
    
    
  // }

  useEffect(() => {
    if (location.pathname.startsWith("/details/view_all_contact/")) {
      setActiveLink("/view_all_contact");
    } else if (location.pathname.startsWith("/details/filters/")) {
      setActiveLink("/filters");
    } else {
      setActiveLink(location.pathname);
    }
  }, [location.pathname]);

  // const handleMouseEnter = () => {
  //   setIsExpanded(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsExpanded(false);
  // };
  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <div
      className={`fixed top-0 left-0 h-full cursor-pointer bg-[#f7f7f7] ${
        isExpanded ? "w-64" : "w-16"
      } transition-all duration-300 overflow-x-hidden`}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div className="bg-[#f7f7f7]">
        <div className="flex-col flex">
          <div className="flex bg-[#f7f7f7] overflow-x-hidden">
            <div
              className={`bg-[#f7f7f7] h-full lg:flex md:w-64 md:flex-col ${
                isExpanded ? "block" : "hidden"
              }`}
            >
               <Tooltip title="Buzznation Marketing" placement="right">
              <div className="flex flex-row mt-2 p-[5px] ">
                <div className="flex h-[35px] w-[50px] object-cover">
                  {isExpanded ? (
                    <>
                    
                      <img
                        alt=""
                        className="h-full w-full"
                        src={buzzLogoSideBar}
                      />
                      <span className="ml-[8px] mt-[3px] text-2xl font-bold">
                        Buzznation
                      </span>
                    </>
                  ) : (
                    <img
                      alt=""
                      className="h-full w-full"
                      src={buzzLogoSideBar}
                    />
                  )}
                </div>
              </div>
              </Tooltip>
              <div className="flex-col pt-5 mt-1 flex overflow-y-auto">
                <div className="h-full flex-col justify-between px-1 flex">
                  <div className="space-y-4">
                    <div className="bg-top bg-cover space-y-1">
                    <Tooltip title="Dashboard" placement="right">
                    <NavLink
                        to="/dashboard"
                        className={`font-medium text-sm items-center rounded-lg  px-4 py-2.5 flex transition-all duration-200 hover-bg-[#42611f] group cursor-pointer ${
                          activeLink === "/dashboard"
                            ? "bg-[#42611f] text-white"
                            : "text-[#595959]"
                        }`}
                        onClick={() => handleLinkClick("/dashboard")}
                      >
                        {isExpanded ? (
                          <>
                            <AiFillHome
                              color={`${
                                activeLink === "/dashboard"
                                  ? "white"
                                  : "#434343"
                              }`}
                              fontSize="1.5em"
                            />
                            <span className="ml-[8px]">Dashboard</span>
                          </>
                        ) : (
                          <AiFillHome
                            color={`${
                              activeLink === "/dashboard" ? "white" : "#434343"
                            }`}
                            fontSize="1.5em"
                          />
                        )}
                      </NavLink>
                    </Tooltip>
                      <Tooltip title="Add Contact" placement="right">
                      <NavLink
                        to="/add_contact"
                        className={`font-medium text-sm items-center rounded-lg  px-4 py-2.5 flex transition-all duration-200 hover-bg-[#42611f] group cursor-pointer ${
                          activeLink === "/add_contact"
                            ? "bg-[#42611f] text-white"
                            : "text-[#595959]"
                        }`}
                        onClick={() => handleLinkClick("/add_contact")}
                      >
                        {isExpanded ? (
                          <>
                            <LuContact2
                              color={`${
                                activeLink === "/add_contact"
                                  ? "white"
                                  : "#434343"
                              }`}
                              fontSize="1.5em"
                            />
                            <span className="ml-[8px]">Add Contact</span>
                          </>
                        ) : (
                          <LuContact2
                            color={`${
                              activeLink === "/add_contact"
                                ? "white"
                                : "#434343"
                            }`}
                            fontSize="1.5em"
                          />
                        )}
                      </NavLink>
                      </Tooltip>
                      <Tooltip title="View All Contacts" placement="right">
                      <NavLink
                        to="/view_all_contact"
                        className={`font-medium text-sm items-center rounded-lg  px-4 py-2.5 flex transition-all duration-200 hover-bg-[#42611f] group cursor-pointer ${
                          activeLink === "/view_all_contact"
                            ? "bg-[#42611f] text-white"
                            : "text-[#595959]"
                        }`}
                        onClick={() => handleLinkClick("/view_all_contact")}
                      >
                        {isExpanded ? (
                          <>
                            <RiContactsBook2Fill
                              color={`${
                                activeLink === "/view_all_contact"
                                  ? "white"
                                  : "#434343"
                              }`}
                              fontSize="1.5em"
                            />
                            <span className="ml-[8px]">View All Contacts</span>
                          </>
                        ) : (
                          <RiContactsBook2Fill
                            color={`${
                              activeLink === "/view_all_contact"
                                ? "white"
                                : "#434343"
                            }`}
                            fontSize="1.5em"
                          />
                        )}
                      </NavLink>
                      </Tooltip>
                      <Tooltip title="Bulk Upload" placement="right">
                      <NavLink
                        to="/bulk_upload"
                        className={`font-medium text-sm items-center rounded-lg  px-4 py-2.5 flex transition-all duration-200 hover-bg-[#42611f] group cursor-pointer ${
                          activeLink === "/bulk_upload"
                            ? "bg-[#42611f] text-white"
                            : "text-[#595959]"
                        }`}
                        onClick={() => handleLinkClick("/bulk_upload")}
                      >
                        {isExpanded ? (
                          <>
                            <FaFileUpload
                              color={`${
                                activeLink === "/bulk_upload"
                                  ? "white"
                                  : "#434343"
                              }`}
                              fontSize="1.5em"
                            />
                            <span className="ml-[8px]">Bulk Upload</span>
                          </>
                        ) : (
                          <FaFileUpload
                            color={`${
                              activeLink === "/bulk_upload"
                                ? "white"
                                : "#434343"
                            }`}
                            fontSize="1.5em"
                          />
                        )}
                      </NavLink>
                      </Tooltip>
                      <Tooltip title="Filters" placement="right">
                      <NavLink
                        to="/filters"
                        className={`font-medium text-sm items-center rounded-lg  px-4 py-2.5 flex transition-all duration-200 hover-bg-[#42611f] group cursor-pointer ${
                          activeLink === "/filters"
                            ? "bg-[#42611f] text-white"
                            : "text-[#595959]"
                        }`}
                        onClick={() => handleLinkClick("/filters")}
                      >
                        {isExpanded ? (
                          <>
                            <HiFilter
                              color={`${
                                activeLink === "/filters" ? "white" : "#434343"
                              }`}
                              fontSize="1.5em"
                            />
                            <span className="ml-[8px]">
                              Account Based Filters
                            </span>
                          </>
                        ) : (
                          <HiFilter
                            color={`${
                              activeLink === "/filters" ? "white" : "#434343"
                            }`}
                            fontSize="1.5em"
                          />
                        )}
                      </NavLink>
                      </Tooltip>
                      <Tooltip title="Email Status Bulk Upload" placement="right">
                      <NavLink
                        to="/upload_email_status"
                        className={`font-medium text-sm items-center rounded-lg  px-4 py-2.5 flex transition-all duration-200 hover-bg-[#42611f] group cursor-pointer ${
                          activeLink === "/upload_email_status"
                            ? "bg-[#42611f] text-white"
                            : "text-[#595959]"
                        }`}
                        onClick={() => handleLinkClick("/upload_email_status")}
                      >
                        {isExpanded ? (
                          <>
                            <MdMarkEmailUnread
                              color={`${
                                activeLink === "/upload_email_status"
                                  ? "white"
                                  : "#434343"
                              }`}
                              fontSize="1.5em"
                            />
                            <span className="ml-[8px]">
                              Account Based Filters
                            </span>
                          </>
                        ) : (
                          <MdMarkEmailUnread
                            color={`${
                              activeLink === "/upload_email_status"
                                ? "white"
                                : "#434343"
                            }`}
                            fontSize="1.5em"
                          />
                        )}
                      </NavLink>
                      </Tooltip>
                      <Tooltip title="Revenue Details & Employee Counts" placement="right">
                      <NavLink
                        to="/revenue_employee_data"
                        className={`font-medium text-sm items-center rounded-lg  px-4 py-2.5 flex transition-all duration-200 hover-bg-[#42611f] group cursor-pointer ${
                          activeLink === "/revenue_employee_data"
                            ? "bg-[#42611f] text-white"
                            : "text-[#595959]"
                        }`}
                        onClick={() =>
                          handleLinkClick("/revenue_employee_data")
                        }
                      >
                        {isExpanded ? (
                          <>
                            <RiMoneyDollarCircleFill
                              color={`${
                                activeLink === "/revenue_employee_data"
                                  ? "white"
                                  : "#434343"
                              }`}
                              fontSize="1.7em"
                            />
                            <span className="ml-[8px]">
                              Revenue & Employee Details
                            </span>
                          </>
                        ) : (
                          <RiMoneyDollarCircleFill
                            color={`${
                              activeLink === "/revenue_employee_data"
                                ? "white"
                                : "#434343"
                            }`}
                            fontSize="1.7em"
                          />
                        )}
                      </NavLink>
                      </Tooltip>
                      <Tooltip title="Upload Documents" placement="right">
                      <NavLink
                        to="/document_bulk_upload"
                        className={`font-medium text-sm items-center rounded-lg  px-4 py-2.5 flex transition-all duration-200 hover-bg-[#42611f] group cursor-pointer ${
                          activeLink === "/document_bulk_upload"
                            ? "bg-[#42611f] text-white"
                            : "text-[#595959]"
                        }`}
                        onClick={() => handleLinkClick("/document_bulk_upload")}
                      >
                        {isExpanded ? (
                          <>
                            <IoDocuments
                              color={`${
                                activeLink === "/document_bulk_upload" ? "white" : "#434343"
                              }`}
                              fontSize="1.5em"
                            />
                            <span className="ml-[8px]">Upload Documents</span>
                          </>
                        ) : (
                          <IoDocuments
                            color={`${
                              activeLink === "/document_bulk_upload" ? "white" : "#434343"
                            }`}
                            fontSize="1.5em"
                          />
                        )}
                      </NavLink>
                      </Tooltip>
                    </div>
                  </div>
                  {/* <div className="mt-6 pb-4"></div> */}
                  <div className="mt-12 pb-4">
                    <div className="bg-top bg-cover space-y-1 ">
                      {/* Moved "Settings" and "Logout" to the end */}
                      {/* <NavLink
                        to="/settings"
                        className={`font-medium text-sm items-center rounded-lg  px-4 py-2.5 flex transition-all duration-200 hover-bg-[#42611f] group cursor-pointer ${
                          activeLink === "/settings"
                            ? "bg-[#42611f] text-white"
                            : "text-[#595959]"
                        }`}
                        onClick={() => handleLinkClick("/settings")}
                      >
                        {isExpanded ? (
                          <>
                            <AiFillSetting
                              color={`${
                                activeLink === "/settings" ? "white" : "#434343"
                              }`}
                              fontSize="1.5em"
                            />
                            <span className="ml-[8px]">Settings</span>
                          </>
                        ) : (
                          <AiFillSetting
                            color={`${
                              activeLink === "/settings" ? "white" : "#434343"
                            }`}
                            fontSize="1.5em"
                          />
                        )}
                      </NavLink> */}
                       <Tooltip title="Logout" placement="right">
                      <NavLink
                        to="/login"
                        className={`font-medium text-sm items-center rounded-lg  px-4 py-2.5 flex transition-all duration-200 hover-bg-[#42611f] group cursor-pointer ${
                          activeLink === "/logout"
                            ? "bg-[#42611f] text-white"
                            : "text-[#595959]"
                        }`}
                        onClick={checkOutUser}
                      >
                        {isExpanded ? (
                          <>
                            <TbLogout
                              color={`${
                                activeLink === "/logout" ? "white" : "#434343"
                              }`}
                              fontSize="1.5em"
                            />
                            <span className="ml-[8px]">Logout</span>
                          </>
                        ) : (
                          <TbLogout
                            color={`${
                              activeLink === "/logout" ? "white" : "#434343"
                            }`}
                            fontSize="1.5em"
                          />
                        )}
                      </NavLink>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
