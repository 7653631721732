function Cards( {icon, heading, subtitle, background}) {
  return (
    <div className='max-w-[350px] w-[350px] p-6 pb-4 bg-white border border-gray-200 rounded-lg shadow-lg'>
     <div className="flex flex-start">
     <div className={`rounded-lg p-2 ${background}`}>
     {icon}
     </div>
     </div>
        <h5 className="mb-2 text-lg pt-2 font-semibold tracking-tight text-gray-900">
         {heading}
        </h5>
      <p className="mb-3 font-normal text-gray-500 dark:text-gray-400 text-sm">
        {subtitle}
      </p>
    </div>
  );
}

export default Cards;
