import { Fragment, useState,useEffect  } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import {AiOutlineUser} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import { TbLogout } from "react-icons/tb";
import axios from 'axios';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

 function Navbar() {
  const navigate = useNavigate();
  const [userName, setUsername] = useState('');
  const [ip, setIP] = useState("");
  const [action, setAction] = useState(0);

  useEffect(() => {
    let userName = localStorage.getItem("userName");
    setUsername(userName);
    getData();
  }, [ip]);

  const getData = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      setIP(res.data.ip);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error appropriately
    }
  };

  const checkOutUser = () => {
    let userName = localStorage.getItem("userName");
    const formData = {
      ip: ip,
      userName: userName,
      action: action,
    };
    
    axios
      .post("https://dbms.buzz-nation.com/phpApi/insertUserLogData.php", formData)
      .then((response) => {
        localStorage.setItem("login", "");
        localStorage.setItem("loginToken", "");
        localStorage.setItem("userName", '');
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };

  // function logoutSubmit() {
  //   checkOutUser();
  //   navigate("/login");
  // }
  return (
    <Disclosure as="nav" className="bg-white drop-shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-12 items-center justify-end">             
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full text-sm focus:outline-none bg-[#87d068] p-1">
                      {/* <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span> */}
                      <AiOutlineUser color="white" fontSize="1.5em" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-[300px] origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {/* <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Your Profile
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Settings
                          </a>
                        )}
                      </Menu.Item> */}
                      <Menu.Item>
                        {({ active }) => (
                          // <a
                          //   href="#"
                          //   className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          //   onClick={logoutSubmit}
                          // >
                          //   Sign out
                          // </a>
                          <div className={classNames(active ? 'bg-gray-100 text-gray-700' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                           <div className='flex'>
                            <span className='pr-2 mt-1'><FaUser /></span>{userName}
                           </div>
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer')}
                            onClick={checkOutUser}
                          >
                            <div className='flex'>
                            <span className='pr-2 mt-1'><TbLogout /></span>Logout
                           </div>
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

        
        </>
      )}
    </Disclosure>
  )
}
export default Navbar;