import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import axios from "axios";
import Breadcrumbs1 from "./breadcrumbs1";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
function AddContact() {
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [isBounced, setIsBounced] = useState(false);
  const [unsubscribedDate, setUnsubscribedDate] = useState(new Date());
  const [bouncedDate, setBouncedDate] = useState(new Date());
  const [enteredEmail, setEnteredEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    verifyEmails();
  }, [enteredEmail]);

  let emailsArray = [];
  const verifyEmails = async () => {
    try {
      const emailResponse = await axios.get(
        `https://dbms.buzz-nation.com/phpApi/getClientEmail.php`
      );
      if (emailResponse.status === 200) {
        const emailResponseData = emailResponse.data.res;
        const emailValues = emailResponseData.map((item) => item.email);
        emailsArray.push(...emailValues);
      } else if (emailResponse.status === 400) {
      } else {
        // setUploadReport("An Error Occured");
      }
    } catch (error) {}
  };

  const isEmailExists = (enteredEmail) => {
    return emailsArray.includes(enteredEmail);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEnteredEmail(value);

    // Check if the entered email already exists
    if (isEmailExists(value)) {
      Swal.fire({
        text: "Email already exists. Please enter a different email",
        icon: "warning",
      });
    }
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form");
    setFormSubmitted(true);
  
    // Gather form data
    const formData = new FormData(e.target);
  
    // Convert FormData to a plain object
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
  
    // Add additional data if needed
    data["isUnsubscribed"] = isUnsubscribed;
    data["isBounced"] = isBounced;
    data["unsubscribedDate"] = unsubscribedDate;
    data["bouncedDate"] = bouncedDate;
  
    const requiredFields = ["first_name", "last_name", "company_name", "email"];
    const missingFields = requiredFields.filter((field) => !data[field]);
  
    if (missingFields.length > 0) {
      Swal.fire({
        text: "Please fill in all required fields",
        icon: "warning",
      });
      return;
    }
  
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  
    if (!emailRegex.test(data.email)) {
      Swal.fire({
        text: "Please enter a valid email",
        icon: "warning",
      });
      return;
    }

    const phoneRegex = /^[0-9]*$/i;

    if (!phoneRegex.test(data.phone_number)) {
      Swal.fire({
        text: "Please enter a valid Phone Number",
        icon: "warning",
      });
      return;
    }

  
    try {
      // Make a POST request to the API
      const response = await axios.post(
        "https://dbms.buzz-nation.com/phpApi/singleContactInsert.php",
        data
      );
  
      if (response.status === 200) {
        Swal.fire({
          text: "Data Inserted Successfully",
          icon: "success",
        });
        setEnteredEmail(""); 
        setFormSubmitted(false);
        e.target.reset();
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };
  

  const handleUnsubscribedChange = (e) => {
    const value = e.target.value;
    setIsUnsubscribed(value === "Yes");
  };

  const handleBouncedChange = (e) => {
    const value = e.target.value;
    setIsBounced(value === "Yes");
  };
  
  return (
    <div className="bg-white">
      <Navbar />
      <Sidebar />
      <div className="ml-[120px] mt-4 mr-[40px]">
        <div>
          <Breadcrumbs1 level1="Add Contact" level1Path="/add_contact" />
        </div>
        <div className="flex">
          <div className="text-xl font-semibold">
            <span>Add Contact</span>
          </div>
        </div>
        <div className="mt-2">
          <div>
            <form onSubmit={handleSubmit}>
              <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-8">
                  <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-4">
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        List Type
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="list_type"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        <span className="text-red-500">*</span>First Name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="first_name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        <span className="text-red-500">*</span>Last Name
                      </label>
                      <div className="mt-2">
                        <input
                          name="last_name"
                          type="text"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        <span className="text-red-500">*</span>Email address
                      </label>
                      <div className="mt-2">
                        <input
                          name="email"
                          type="email"
                          autoComplete="email"
                          value={enteredEmail}
                          onChange={handleEmailChange}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        <span className="text-red-500">*</span>Company Name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="company_name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Job Title
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="job_title"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Phone Number
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="phone_number"
                          maxLength={10}
                          minLength={10}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        City
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="city"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        State
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="state"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Country
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="country"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Industry
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="industry"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Database Source
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="database_type"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Lead Status
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="status"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Linkedin URL
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="linkedin_url"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="unsubscribed"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Unsubscribed
                      </label>
                      <div className="mt-2">
                        <select
                          onChange={handleUnsubscribedChange}
                          name="unsubscribed"
                          defaultValue="No"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:max-w-xs sm:text-sm sm:leading-6"
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>
                    {isUnsubscribed && (
                      <div className="sm:col-span-1">
                        <label
                          htmlFor="unsubscribed"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Unsubscribed Date
                        </label>
                        <div className="mt-2">
                          <DatePicker
                            selected={unsubscribedDate}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                            onChange={(date) => setUnsubscribedDate(date)}
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </div>
                    )}
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Bounced
                      </label>
                      <div className="mt-2">
                        <select
                          onChange={handleBouncedChange}
                          name="bounced"
                          defaultValue="No"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:max-w-xs sm:text-sm sm:leading-6"
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>
                    {isBounced && (
                      <div className="sm:col-span-1">
                        <label
                          htmlFor="unsubscribed"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Bounced Date
                        </label>
                        <div className="mt-2">
                          <DatePicker
                            selected={bouncedDate}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                            onChange={(date) => setBouncedDate(date)}
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </div>
                    )}
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Bounced Reason
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="bounced_reason"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Remarks
                      </label>
                      <div className="mt-2">
                        <textarea 
                          name="remarks"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                        ></textarea>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="mt-4 flex items-center justify-end gap-x-6">
                <button
                  type="submit"
                  className="rounded-md bg-[#16a34a] px-3 py-2 text-sm font-semibold text-white shadow-sm"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddContact;
