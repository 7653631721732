import React, { useState, useEffect,Fragment, useRef } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { FaSort, FaSearch } from "react-icons/fa";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import SpinningIcon from "./icon/SpinningIcon";
import { IoIosArrowDown } from "react-icons/io";
import Breadcrumbs1 from "./breadcrumbs1";
import { MdOutlineClear } from "react-icons/md";
import Swal from "sweetalert2";
import { IoAttachSharp } from "react-icons/io5";
import { Dialog, Transition } from "@headlessui/react";
function AccountFilters() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  //company
  const [companyName, setCompanyName] = useState([]);
  const [companyNameArray, setCompanyNameArray] = useState([]);
  const [isCompanyDropdownOpen, setCompanyDropdownOpen] = useState(false);
  const [companySearchTerm, setCompanySearchTerm] = useState("");
  //industry
  const [industryName, setIndustryName] = useState([]);
  const [industryNameArray, setIndustryNameArray] = useState([]);
  const [isIndustryDropdownOpen, setIndustryDropdownOpen] = useState(false);
  const [industrySearchTerm, setIndustrySearchTerm] = useState("");
  //listType
  const [listTypeName, setListTypeName] = useState([]);
  const [listTypeArray, setListTypeArray] = useState([]);
  const [isListTypeDropdownOpen, setListTypeDropdownOpen] = useState(false);
  const [listTypeSearchTerm, setListTypeSearchTerm] = useState("");
  //country
  const [countryName, setCountryName] = useState([]);
  const [countryArray, setCountryArray] = useState([]);
  const [isCountryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState("");

  //Job Title
  const [jobTitle, setJobTitle] = useState([]);
  const [jobTitleArray, setJobTitleArray] = useState([]);
  const [isJobTitleDropdownOpen, setJobTitleDropdownOpen] = useState(false);
  const [jobTitleSearchTerm, setJobTitleSearchTerm] = useState("");

  //filteredData
  const [dataArray, setDataArray] = useState([]);

  //showClearFilterButton
  const [showButton, setShowButton] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const [loading, setLoading] = useState(true);

  const [currentModalOpen, setCurrentModalOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [headers, setHeaders] = useState([]);
  const [checkedHeaders, setCheckedHeaders] = useState([]);

  useEffect(() => {
    getCompanyNames();
    getIndustryNames();
    getListType();
    getCountry();
    getFilteredData();
    getJobTitle();
    if (companyName.length > 0 || industryName.length > 0 || listTypeName.length > 0 || countryName.length > 0 || jobTitle.length > 0) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
    setCurrentPage(1);
  }, [companyName, industryName, listTypeName, countryName,jobTitle]);

  const closeAllDropdownsExcept = (exceptDropdown) => {
    setCompanyDropdownOpen(false);
    setIndustryDropdownOpen(false);
    setListTypeDropdownOpen(false);
    setCountryDropdownOpen(false);
    setJobTitleDropdownOpen(false);

    // Set the specified dropdown to true
    switch (exceptDropdown) {
      case "company":
        setCompanyDropdownOpen(true);
        break;
      case "industry":
        setIndustryDropdownOpen(true);
        break;
      case "listType":
        setListTypeDropdownOpen(true);
        break;
      case "country":
        setCountryDropdownOpen(true);
        break;
      case "jobTitle":
        setJobTitleDropdownOpen(true);
        break;
      default:
        break;
    }
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = () => {
    const sortableData = [...dataArray];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  };


  //company Dropdown
  const handleCompanyDropdownButtonClick = () => {
    setCompanyDropdownOpen(
      (prevIsCompanyDropdownOpen) => !prevIsCompanyDropdownOpen
    );
    if (!isCompanyDropdownOpen) {
      closeAllDropdownsExcept("company");
    }
  };

  const handleSearchCompanyInputChange = (f) => {
    setCompanySearchTerm(f.target.value.toLowerCase());
  };


  const handleCompanyOptionClick = (selectedCompany) => {
    if (selectedCompany === '') {
      setCompanyName([]);
    } else {
      const isSelected = companyName.includes(selectedCompany);
      if (isSelected) {
        setCompanyName((prevNames) =>
          prevNames.filter((name) => name !== selectedCompany)
        );
      } else {
        setCompanyName((prevNames) => [...prevNames, selectedCompany]);
      }
    }
    setCompanyDropdownOpen(false); 
  };

 

  const getCompanyNames = async () => {
    const postData = {
      industry : industryName,
      listType : listTypeName,
      country : countryName,
      jobTitle : jobTitle
    }
    const companyNamesArray = [];
    try {
      const companyNameResponse = await axios.post(
        `https://dbms.buzz-nation.com/phpApi/getAccountNames.php`, postData
      );
      if (companyNameResponse.status === 200) {
        const companyNameResponseData = companyNameResponse.data.res;
        const companyNameValues = companyNameResponseData.map((item) => ({
          company_name: item.company_name,
          company_count: item.company_count,
        }));
        const validCompanyValues = companyNameValues.filter(
          (value) =>
            value.company_name.trim() !== "" &&
            value.company_count.trim() !== ""
        );
        const strAscending = [...validCompanyValues].sort((a, b) =>
          a.company_name > b.company_name ? 1 : -1
        );
        companyNamesArray.push(...strAscending);
      } else if (companyNameResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    }
    setCompanyNameArray(companyNamesArray);
  };
  

  // Industry Dropdown

  const handleIndustryDropdownButtonClick = () => {
    setIndustryDropdownOpen(
      (prevIsIndustryDropdownOpen) => !prevIsIndustryDropdownOpen
    );

    if (!isIndustryDropdownOpen) {
      closeAllDropdownsExcept("industry");
    }
  };

  const handleSearchIndustryInputChange = (f) => {
    setIndustrySearchTerm(f.target.value.toLowerCase());
  };
  
  const handleIndustryOptionClick = (selectedIndustry) => {
    const isSelected = industryName.includes(selectedIndustry);
  
    if (isSelected) {
      setIndustryName((prevIndustries) =>
        prevIndustries.filter((industry) => industry !== selectedIndustry)
      );
    } else {
      setIndustryName((prevIndustries) => [...prevIndustries, selectedIndustry]);
    }
  
    setIndustryDropdownOpen(false); 
  };
  

  const getIndustryNames = async () => {
    const postData = {
      companyName : companyName,
      listType : listTypeName,
      country : countryName,
      jobTitle : jobTitle
    }
    const industryNamesArray = [];
    try {
      const industryNameResponse = await axios.post(
        `https://dbms.buzz-nation.com/phpApi/getIndustry.php`,postData
      );
      if (industryNameResponse.status === 200) {
        const industryNameResponseData = industryNameResponse.data.res;
        const industryNameValues = industryNameResponseData.map((item) => ({
          industry: item.industry,
          industry_count: item.industry_count,
        }));
        const validIndustryValues = industryNameValues.filter(
          (value) =>
            value.industry.trim() !== "" && value.industry_count.trim() !== ""
        );
        const strAscending = [...validIndustryValues].sort((a, b) =>
          a.industry > b.industry ? 1 : -1
        );
        industryNamesArray.push(...strAscending);
      } else if (industryNameResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    }
    setIndustryNameArray(industryNamesArray);
  };

  //ListType
  const handleListTypeDropdownButtonClick = () => {
    setListTypeDropdownOpen(
      (prevIsListTypeDropdownOpen) => !prevIsListTypeDropdownOpen
    );

    // Close other dropdowns if the current list type dropdown is opened
    if (!isListTypeDropdownOpen) {
      closeAllDropdownsExcept("listType");
    }
  };

  const handleSearchListTypeInputChange = (f) => {
    setListTypeSearchTerm(f.target.value.toLowerCase());
  };
  const handleListTypeOptionClick = (selectedListType) => {
    const isSelected = listTypeName.includes(selectedListType);
  
    if (isSelected) {
      setListTypeName((prevListTypes) =>
        prevListTypes.filter((type) => type !== selectedListType)
      );
    } else {
      setListTypeName((prevListTypes) => [...prevListTypes, selectedListType]);
    }
  
    setListTypeDropdownOpen(false); // Close the dropdown after selecting an option
  };
  

  const getListType = async () => {
    const postData = {
      companyName : companyName,
      industry : industryName,
      country : countryName,
      jobTitle : jobTitle
    }
    const listTypeArray = [];
    try {
      const listTypeResponse = await axios.post(
        `https://dbms.buzz-nation.com/phpApi/getListType.php`,postData
      );
      if (listTypeResponse.status === 200) {
        const listTypeResponseData = listTypeResponse.data.res;
        const listTypeValues = listTypeResponseData.map((item) => ({
          list_type: item.list_type,
          list_type_count: item.list_type_count,
        }));
        const validListTypeValues = listTypeValues.filter(
          (value) =>
            value.list_type.trim() !== "" && value.list_type_count.trim() !== ""
        );
        const strAscending = [...validListTypeValues].sort((a, b) =>
          a.list_type > b.list_type ? 1 : -1
        );
        listTypeArray.push(...strAscending);
      } else if (listTypeResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    }
    setListTypeArray(listTypeArray);
  };

  //Country

  const handleCountryDropdownButtonClick = () => {
    setCountryDropdownOpen(
      (prevIsCountryDropdownOpen) => !prevIsCountryDropdownOpen
    );

    // Close other dropdowns if the current country dropdown is opened
    if (!isCountryDropdownOpen) {
      closeAllDropdownsExcept("country");
    }
  };

  const handleSearchCountryInputChange = (f) => {
    setCountrySearchTerm(f.target.value.toLowerCase());
  };
  const handleCountryOptionClick = (selectedCountry) => {
    const isSelected = countryName.includes(selectedCountry);
  
    if (isSelected) {
      setCountryName((prevCountries) =>
        prevCountries.filter((country) => country !== selectedCountry)
      );
    } else {
      setCountryName((prevCountries) => [...prevCountries, selectedCountry]);
    }
  
    setCountryDropdownOpen(false); // Close the dropdown after selecting an option
  };
  

  const getCountry = async () => {
    const postData = {
      companyName : companyName,
      industry : industryName,
      listType : listTypeName,
      jobTitle : jobTitle
    }
    const countryArray = [];
    try {
      const countryResponse = await axios.post(
        `https://dbms.buzz-nation.com/phpApi/getCountry.php`,postData
      );
      if (countryResponse.status === 200) {
        const countryResponseData = countryResponse.data.res;
        const countryValues = countryResponseData.map((item) => ({
          country: item.country,
          country_count: item.country_count,
        }));
        const validCountryValues = countryValues.filter(
          (value) =>
            value.country.trim() !== "" && value.country_count.trim() !== ""
        );
        const strAscending = [...validCountryValues].sort((a, b) =>
        a.country > b.country ? 1 : -1
      );
        countryArray.push(...strAscending);
      } else if (countryResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    }
    setCountryArray(countryArray);
  };

  //filteredData
  const getFilteredData = async () => {
    setLoading(true);
    const postData = {
      companyName : companyName,
      industry : industryName,
      listType : listTypeName,
      country : countryName,
      jobTitle : jobTitle
    }
    try {
      const dataResponse = await axios.post(
        'https://dbms.buzz-nation.com/phpApi/getFilteredData.php',postData
      );
      if (dataResponse.status === 200) {
        const dataResponseData = dataResponse.data.res;
        setDataArray(dataResponseData);
      } else if (dataResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const resetFilters = () => {
    setCompanyName([]);
    setIndustryName([]);
    setCountryName([]);
    setListTypeName([]);
    setJobTitle([]);
    closeAllDropdownsExcept('');
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  let searchedDataArray = [];
  const filteredData = () => {
    const sortedDataArray = sortedData();
    searchedDataArray = sortedDataArray.filter((contact) =>
      Object.values(contact).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const currentItems = () => {
    filteredData();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Return the portion of data for the current page
    return searchedDataArray.slice(startIndex, endIndex);
  };

  const handleJobTitleDropdownButtonClick = () => {
    setJobTitleDropdownOpen(
      (prevIsJobTitleDropdownOpen) => !prevIsJobTitleDropdownOpen
    );

    // Close other dropdowns if the current company dropdown is opened
    if (!isJobTitleDropdownOpen) {
      closeAllDropdownsExcept("jobTitle");
    }
  };

  const handleSearchJobTitleInputChange = (f) => {
    setJobTitleSearchTerm(f.target.value.toLowerCase());
  };
  const handleJobTitleOptionClick = (selectedJobTitle) => {
    const isSelected = jobTitle.includes(selectedJobTitle);
  
    if (isSelected) {
      setJobTitle((prevJobTitle) =>
      prevJobTitle.filter((jobTitle) => jobTitle !== selectedJobTitle)
      );
    } else {
      setJobTitle((prevCountries) => [...prevCountries, selectedJobTitle]);
    }
  
    setJobTitleDropdownOpen(false); // Close the dropdown after selecting an option
  };

  const getJobTitle = async () => {
    const postData = {
      companyName : companyName,
      industry : industryName,
      listType : listTypeName,
      country : countryName
    }
    const JobTitleArray = [];
    try {
      const jobTitleResponse = await axios.post(
        `https://dbms.buzz-nation.com/phpApi/getJobTitle.php`,postData
      );
      if (jobTitleResponse.status === 200) {
        const jobTitleResponseData = jobTitleResponse.data.res;
        const jobTitleValues = jobTitleResponseData.map((item) => ({
          job_title: item.job_title,
          job_title_count: item.job_title_count,
        }));
        const validJobTitleValues = jobTitleValues.filter(
          (value) =>
            value.job_title.trim() !== "" && value.job_title_count.trim() !== ""
        );
        const strAscending = [...validJobTitleValues].sort((a, b) =>
        a.job_title > b.job_title ? 1 : -1
      );
      JobTitleArray.push(...strAscending);
      } else if (jobTitleResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    }
    setJobTitleArray(JobTitleArray);
  };

  const handleItemsPerPageChange = (value) => {
    setCurrentPage(1); // Reset to the first page when changing items per page
    setItemsPerPage(value);
  };

  useEffect(() => {
    setTotalRecords(searchedDataArray.length);
  }, [searchedDataArray]);

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === Math.ceil(totalRecords / itemsPerPage);

  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  const getPageNumbers = () => {
    if (totalPages <= 5) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    let pages = [1];

    if (currentPage > 2) {
      pages.push("...");
    }

    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(currentPage + 1, totalPages - 1);
      i++
    ) {
      pages.push(i);
    }

    if (currentPage < totalPages - 2) {
      pages.push("...");
    }

    pages.push(totalPages);

    return pages;
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const allItemIds = searchedDataArray.map((contact) => contact.id);

    // If "Select All" is checked, set all item ids to selectedItems
    // If unchecked, clear the selected items
    setSelectedItems(isChecked ? allItemIds : []);
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        // If the item is already selected, remove it
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        // If the item is not selected, add it
        return [...prevSelectedItems, itemId];
      }
    });
  };

  function exportToExcel() {
    if (selectedItems.length == 0) {
      Swal.fire({
        text: "Kindly select data to export",
        icon: "warning",
      });
    } else {

      setCurrentModalOpen(true);
      const headers = Object.keys(dataArray[0]);
      const headersWithoutIdAndDocName = headers.filter(
        (key) => key !== "id" && key !== "doc_name" && key !== "created_date" && key !== "modified_date"
      );

      setHeaders(headersWithoutIdAndDocName);
    }
  }

  const handleRemoveCountry = (index) => {
    const updatedCountryName = [...countryName];
    updatedCountryName.splice(index, 1);
    setCountryName(updatedCountryName);
  };

  const handleRemoveListType = (index) => {
    const updatedListTypeName = [...listTypeName];
    updatedListTypeName.splice(index, 1);
    setListTypeName(updatedListTypeName);
  };
  const handleRemoveCompany = (index) => {
    const updatedCompanyName = [...companyName];
    updatedCompanyName.splice(index, 1);
    setCompanyName(updatedCompanyName);
  };
  const handleRemoveJobTitle = (index) => {
    const updatedJobTitle = [...jobTitle];
    updatedJobTitle.splice(index, 1);
    setJobTitle(updatedJobTitle);
  };

  const handleRemoveIndustry = (index) => {
    const updatedIndustryName = [...industryName];
    updatedIndustryName.splice(index, 1);
    setIndustryName(updatedIndustryName);
  };

  const handleHeaderChange = (header) => {
    const isSelected = checkedHeaders.includes(header);
    if (isSelected) {
      setCheckedHeaders((prev) => prev.filter((item) => item !== header));
    } else {
      setCheckedHeaders((prev) => [...prev, header]);
    }
  };

  const finalExport = () => {
    if (checkedHeaders.length == 0) {
      Swal.fire({
        text: "Kindly select atleast one header to export",
        icon: "warning",
      });
    } else {
      setCurrentModalOpen(false);
      const isSelectAllChecked = selectedItems.length === dataArray.length;

      const selectedData = isSelectAllChecked
        ? dataArray.map((entry) =>
            checkedHeaders.reduce((acc, header) => {
              acc[header] = entry[header];
              return acc;
            }, {})
          )
        : dataArray
            .filter((contact) => selectedItems.includes(contact.id))
            .map((entry) =>
              checkedHeaders.reduce((acc, header) => {
                acc[header] = entry[header]; // Include selected header and its value
                return acc;
              }, {})
            );

      const ws = XLSX.utils.json_to_sheet(selectedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Data Sheet");

      // Generate a unique file name
      const fileName = "data-export-" + new Date().getTime() + ".xlsx";

      XLSX.writeFile(wb, fileName);
    }
  };

  return (
    <div className="bg-white">
      <Navbar />
      <Sidebar />
      <div className="ml-[120px] mt-4 mr-[40px]">
        <div className="-ml-[16px]">
          <Breadcrumbs1 level1="Account Based Filters" level1Path="/filters" />
        </div>
        <div className="flex mt-4">
          <div className="text-xl font-semibold -ml-4">
            <span>Account Based Filters</span>
          </div>
        </div>
        <div className="mt-4 flex">
          <div className=" flex items-center mr-4 -ml-[12px]">
            <label className="inline-block mr-2 text-sm font-medium text-gray-900">
              Show Records:
            </label>
            <select
              className="border border-gray-300 rounded-full text-xs font-bold text-gray-600 pl-5 pr-8 bg-white hover:border-gray-400 focus:outline-none appearance-none"
              value={itemsPerPage}
              onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div className="flex ml-auto mr-8">
            <div className="relative mr-4">
              <span className="absolute inset-y-0 left-0 px-2  flex items-center">
                <FaSearch className="text-gray-500" />
              </span>
              <input
                className="border border-gray-300 rounded-full text-xs font-semibold pl-8 pr-8 bg-white hover:border-gray-400 focus:outline-none"
                id="username"
                type="text"
                placeholder="Search Records"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>

            <div className="ml-4 mr-4">
              <button
                className="bg-[#16a34a] py-[7px] px-4 rounded text-xs text-white font-semibold -ml-4"
                onClick={exportToExcel}
              >
                Export
              </button>
            </div>

            {totalPages > 1 && (
              <div>
                <ul className="flex justify-center">
                  {!isFirstPage && (
                    <li
                      className="relative block rounded-2xl px-3 py-1.5 text-xl transition-all duration-300 hover:bg-neutral-100"
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                    >
                      <GrFormPrevious />
                    </li>
                  )}
                  {getPageNumbers().map((page, index) => (
                    <li
                      key={index}
                      className={`relative block rounded-2xl  px-3 py-1.5 text-xs  transition-all duration-300 ${
                        page === currentPage && page !== "..."
                          ? "bg-[#84cc16] text-white"
                          : "bg-transparent text-black hover:bg-neutral-100"
                      }`}
                      onClick={() => (page !== "..." ? paginate(page) : null)}
                    >
                      {page}
                    </li>
                  ))}
                  {!isLastPage && (
                    <li
                      className="relative block rounded-2xl px-3 py-1.5 text-xl transition-all duration-300 hover:bg-neutral-100"
                      onClick={() =>
                        setCurrentPage((prev) =>
                          Math.min(
                            prev + 1,
                            Math.ceil(totalRecords.length / itemsPerPage)
                          )
                        )
                      }
                    >
                      <GrFormNext />
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="mt-4 -ml-4 flex">
          <div className="flex">
            {/* company */}
            <div className="mr-2">
              <div className="">
                <button
                  id="dropdown-button"
                  onClick={handleCompanyDropdownButtonClick}
                  className="border border-gray-300 py-2 rounded-full flex items-center text-xs font-bold text-gray-600 pl-5 pr-3 bg-white hover:border-gray-400 focus:outline-none appearance-none"
                >
                  <span className="mr-2">
                    {"All Accounts"}
                  </span>
                  <IoIosArrowDown fontSize="12px" color="" />
                </button>
                {isCompanyDropdownOpen && (
                  <div className="relative">
                    <div
                      id="dropdown-menu"
                      className="absolute z-10 top-0 left-0 mt-2 w-[200px] max-h-[200px] text-xs overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-2 space-y-1"
                    >
                      <input
                        id="search-input"
                        className="block w-full py-1 px-2 text-xs text-gray-800 border rounded-md border-gray-300 focus:outline-none placeholder:text-xs placeholder:font-medium"
                        type="text"
                        placeholder="Search Accounts"
                        value={companySearchTerm}
                        onChange={handleSearchCompanyInputChange}
                      />
                      {companyNameArray
                        .filter((company) =>
                          company.company_name
                            .toLowerCase()
                            .includes(companySearchTerm)
                        )
                        .map((company, index) => (
                          <div key={index} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={companyName.includes(company.company_name)}
                              onChange={() =>
                                handleCompanyOptionClick(company.company_name)
                              }
                              className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                            />
                            <a
                              className="block px-2 py-1 text-gray-700 text-xs font-medium hover:bg-gray-100 cursor-pointer"
                              onClick={() =>
                                handleCompanyOptionClick(company.company_name)
                              }
                            >
                              {company.company_name} ({company.company_count})
                            </a>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* job Title */}
            <div className="mr-2">
              <div className="">
                <button
                  id="dropdown-button"
                  onClick={handleJobTitleDropdownButtonClick}
                  className="border border-gray-300 py-2 rounded-full flex items-center text-xs font-bold text-gray-600 pl-5 pr-3 bg-white hover:border-gray-400 focus:outline-none appearance-none"
                >
                  <span className="mr-2">
                    {"All Job Title"}
                  </span>
                  <IoIosArrowDown fontSize="12px" color="" />
                </button>
                {isJobTitleDropdownOpen && (
                  <div className="relative">
                    <div
                      id="dropdown-menu"
                      className="absolute z-10 top-0 left-0 mt-2 w-[200px] max-h-[200px] text-xs overflow-y-auto overflow-x-hidden rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-2 space-y-1"
                    >
                      <input
                        id="search-input"
                        className="block w-full py-1 px-2 text-xs text-gray-800 border rounded-md border-gray-300 focus:outline-none placeholder:text-xs placeholder:font-medium"
                        type="text"
                        placeholder="Search Job Title"
                        value={jobTitleSearchTerm}
                        onChange={handleSearchJobTitleInputChange}
                      />
                      {jobTitleArray
                        .filter((job) =>
                        job.job_title
                            .toLowerCase()
                            .includes(jobTitleSearchTerm)
                        )
                        .map((job, index) => (
                          <div key={index} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={jobTitle.includes(job.job_title)}
                              onChange={() =>
                                handleJobTitleOptionClick(job.job_title)
                              }
                              className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                            />
                            <a
                              className="block px-2 py-1 text-gray-700 text-xs font-medium hover:bg-gray-100 cursor-pointer"
                              onClick={() =>
                                handleJobTitleOptionClick(job.job_title)
                              }
                            >
                              {job.job_title} ({job.job_title_count})
                            </a>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* industry */}
            <div className="mr-2">
              <div className="">
                <button
                  id="dropdown-button"
                  onClick={handleIndustryDropdownButtonClick}
                  className="border border-gray-300 py-2 rounded-full flex items-center text-xs font-bold text-gray-600 pl-5 pr-3 bg-white hover:border-gray-400 focus:outline-none appearance-none"
                >
                  <span className="mr-2">
                    {"All Industry"}
                  </span>
                  <IoIosArrowDown fontSize="12px" color="" />
                </button>
                {isIndustryDropdownOpen && (
                  <div className="relative">
                    <div
                      id="dropdown-menu"
                      className="absolute z-10 top-0 left-0 mt-2 w-[200px] max-h-[200px]  overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-2 space-y-1"
                    >
                      <input
                        id="search-input"
                        className="block w-full py-1 px-2 text-xs text-gray-800 border rounded-md border-gray-300 focus:outline-none placeholder:text-xs placeholder:font-medium"
                        type="text"
                        placeholder="Search Industry"
                        value={industrySearchTerm}
                        onChange={handleSearchIndustryInputChange}
                      />
                     
                      {industryNameArray
                        .filter((industry) =>
                          industry.industry
                            .toLowerCase()
                            .includes(industrySearchTerm)
                        )
                        .map((industry, index) => (
                          <div key={index} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={industryName.includes(industry.industry)}
                              onChange={() =>
                                handleIndustryOptionClick(industry.industry)
                              }
                              className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                            />
                          <a
                            key={index}
                            className="block px-2 py-1 text-gray-700 text-xs font-medium hover:bg-gray-100 cursor-pointer"
                            onClick={() =>
                              handleIndustryOptionClick(industry.industry)
                            }
                          >
                            {industry.industry} ({industry.industry_count})
                          </a>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* listType */}
            <div className="mr-2">
              <div className="">
                <button
                  id="dropdown-button"
                  onClick={handleListTypeDropdownButtonClick}
                  className="border border-gray-300 py-2 rounded-full flex items-center text-xs font-bold text-gray-600 pl-5 pr-3 bg-white hover:border-gray-400 focus:outline-none appearance-none"
                >
                  <span className="mr-2">
                    {"All List Type"}
                  </span>
                  <IoIosArrowDown fontSize="12px" color="" />
                </button>
                {isListTypeDropdownOpen && (
                  <div className="relative">
                    <div
                      id="dropdown-menu"
                      className="absolute z-10 top-0 left-0 mt-2 w-[200px] max-h-[200px] overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-2 space-y-1"
                    >
                      <input
                        id="search-input"
                        className="block w-full py-1 px-2 text-xs text-gray-800 border rounded-md border-gray-300 focus:outline-none placeholder:text-xs placeholder:font-medium"
                        type="text"
                        placeholder="Search List Type"
                        value={listTypeSearchTerm}
                        onChange={handleSearchListTypeInputChange}
                      />
                      {listTypeArray
                        .filter((listType) =>
                          listType.list_type
                            .toLowerCase()
                            .includes(listTypeSearchTerm)
                        )
                        .map((listType, index) => (
                          <div key={index} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={listTypeName.includes(listType.list_type)}
                              onChange={() =>
                                handleListTypeOptionClick(listType.list_type)
                              }
                              className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                            />
                          <a
                            key={index}
                            className="block px-2 py-1 text-gray-700 text-xs font-medium hover:bg-gray-100 cursor-pointer"
                            onClick={() =>
                              handleListTypeOptionClick(listType.list_type)
                            }
                          >
                            {listType.list_type} ({listType.list_type_count})
                          </a>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* country */}
            <div className="mr-2">
              <div className="">
                <button
                  id="dropdown-button"
                  onClick={handleCountryDropdownButtonClick}
                  className="border border-gray-300 py-2 rounded-full flex items-center text-xs font-bold text-gray-600 pl-5 pr-3 bg-white hover:border-gray-400 focus:outline-none appearance-none"
                >
                  <span className="mr-2">
                    {"All Country"}
                  </span>
                  <IoIosArrowDown fontSize="12px" color="" />
                </button>
                {isCountryDropdownOpen && (
                  <div className="relative">
                    <div
                      id="dropdown-menu"
                      className="absolute z-10 top-0 left-0 mt-2 w-[200px] max-h-[200px] overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-2 space-y-1"
                    >
                      <input
                        id="search-input"
                        className="block w-full py-1 px-2 text-xs text-gray-800 border rounded-md border-gray-300 focus:outline-none placeholder:text-xs placeholder:font-medium"
                        type="text"
                        placeholder="Search Country"
                        value={countrySearchTerm}
                        onChange={handleSearchCountryInputChange}
                      />
                      {countryArray
                        .filter((country) =>
                          country.country
                            .toLowerCase()
                            .includes(countrySearchTerm)
                        )
                        .map((country, index) => (
                          <div key={index} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={countryName.includes(country.country)}
                              onChange={() =>
                                handleCountryOptionClick(country.country)
                              }
                              className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                            />
                          <a
                            key={index}
                            className="block px-2 py-1 text-gray-700 text-xs font-medium hover:bg-gray-100 cursor-pointer"
                            onClick={() =>
                              handleCountryOptionClick(country.country)
                            }
                          >
                            {country.country} ({country.country_count})
                          </a>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            
          </div>
          
        </div>
        <div className="mt-2 -ml-4 flex flex-wrap">
            {/* clear */}
            {showButton && (
              <div className="mx-2 mt-1">
                  <button
                    id="dropdown-button"
                    onClick={resetFilters}
                    className="border border-gray-300 py-2 rounded-full flex items-center text-xs font-bold text-white pl-5 pr-3 bg-[#84CC16] hover:border-gray-400 focus:outline-none appearance-none"
                  >
                    <span className="mr-2">Clear Filters</span>
                    <MdOutlineClear fontSize="12px" color="" />
                  </button>
              </div>
            )}
            {companyName.length > 0 && companyName.map((company, index) => (
                <div className="mx-1 mt-1" key={index}>
                  <button
                    id="dropdown-button"
                    onClick={() => handleRemoveCompany(index)}
                    className="border border-[#4D7C0F] py-2 rounded-full flex items-center text-xs font-bold text-[#4D7C0F] pl-5 pr-3 bg-[#F7FEE7] appearance-none"
                  >
                    <span className="mr-2">{company}</span>
                    <MdOutlineClear fontSize="12px" color="" />
                  </button>
                </div>
              ))}
              {jobTitle.length > 0 && jobTitle.map((jobTitle, index) => (
                <div className="mx-1 mt-1" key={index}>
                  <button
                    id="dropdown-button"
                    onClick={() => handleRemoveJobTitle(index)}
                    className="border border-[#4D7C0F] py-2 rounded-full flex items-center text-xs font-bold text-[#4D7C0F] pl-5 pr-3 bg-[#F7FEE7] appearance-none"
                  >
                    <span className="mr-2">{jobTitle}</span> 
                    <MdOutlineClear fontSize="12px" color="" />
                  </button>
                </div>
              ))}
               {industryName.length > 0 && industryName.map((industry, index) => (
                <div className="mx-1 mt-1" key={index}>
                  <button
                    id="dropdown-button"
                    onClick={() => handleRemoveIndustry(index)}
                    className="border border-[#4D7C0F] py-2 rounded-full flex items-center text-xs font-bold text-[#4D7C0F] pl-5 pr-3 bg-[#F7FEE7] appearance-none"
                  >
                    <span className="mr-2">{industry}</span>
                    <MdOutlineClear fontSize="12px" color="" />
                  </button>
                </div>
              ))}
               {listTypeName.length > 0 && listTypeName.map((listType, index) => (
                <div className="mx-1 mt-1" key={index}>
                  <button
                    id="dropdown-button"
                    onClick={() => handleRemoveListType(index)}
                    className="border border-[#4D7C0F] py-2 rounded-full flex items-center text-xs font-bold text-[#4D7C0F] pl-5 pr-3 bg-[#F7FEE7] appearance-none"
                  >
                    <span className="mr-2">{listType}</span>
                    <MdOutlineClear fontSize="12px" color="" />
                  </button>
                </div>
              ))}
               {countryName.length > 0 && countryName.map((country, index) => (
                <div className="mx-1 mt-1" key={index}>
                  <button
                    id="dropdown-button"
                    onClick={() => handleRemoveCountry(index)}
                    className="border border-[#4D7C0F] py-2 rounded-full flex items-center text-xs font-bold text-[#4D7C0F] pl-5 pr-3 bg-[#F7FEE7] appearance-none"
                  >
                    <span className="mr-2">{country}</span>
                    <MdOutlineClear fontSize="12px" color="" />
                  </button>
                </div>
              ))}
               
        </div>
        <div className="mt-2">
          <div className="-ml-[12px] -mb-2 mr-10">
            <span className=" text-xs font-semibold text-gray-700">
              {totalRecords ? totalRecords : 0} records found
            </span>
          </div>
        </div>
        <div className="-ml-[20px]">
          <div className="py-4 overflow-x-auto">
            {loading ? (
              <div className="flex justify-center items-center">
                <SpinningIcon />
              </div>
            ) : (
              <div className="ml-2 inline-block min-w-[99%] shadow rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 w-[50px] max-w-[50px] tracking-wider">
                        <div className="flex flex-row">
                          <input
                            type="checkbox"
                            id="selectAll"
                            className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 "
                            checked={selectedItems.length === dataArray.length}
                            onChange={handleSelectAll}
                          />
                          <span className="ml-1">All</span>
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          Source Id
                          {/* <FaSort onClick={() => requestSort("id")} /> */}
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          List Type
                          <FaSort onClick={() => requestSort("list_type")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          First Name
                          <FaSort onClick={() => requestSort("first_name")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          Last Name
                          <FaSort onClick={() => requestSort("last_name")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          Email Address <FaSort onClick={() => requestSort("email")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          Company
                          <FaSort onClick={() => requestSort("company_name")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          Title
                          <FaSort onClick={() => requestSort("job_title")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 ">
                        <div className="flex flex-row">
                          Employee Count
                          <FaSort onClick={() => requestSort("employee_count")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          Revenue
                          <FaSort onClick={() => requestSort("revenue")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          Attachment
                          {/* <FaSort onClick={() => requestSort("created_date")} /> */}
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                         Remarks
                          <FaSort onClick={() => requestSort("remarks")} />
                        </div>
                      </th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems().length > 0 ? (
                      currentItems().map((contact) => (
                        <tr key={contact.id}>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white w-[30px] max-w-[30px] text-xs">
                            <p className="text-gray-900 whitespace-no-wrap">
                              <input
                                type="checkbox"
                                className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 "
                                checked={selectedItems.includes(contact.id)}
                                onChange={() =>
                                  handleCheckboxChange(contact.id)
                                }
                              />
                            </p>
                          </td>
                          <td
                            className="cursor-pointer px-2 py-2 border-b border-gray-200 bg-white text-xs "
                            onClick={() => {
                              navigate(`/details/filters/${contact.id}`);
                            }}
                          >
                            <div className="flex">
                              <div className="ml-1">
                                <p className="text-gray-900 font-bold whitespace-no-wrap hover:text-[#84cc16]">
                                  {contact.id}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs capitalize max-w-[100px] ">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {contact.list_type}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs capitalize ">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {contact.first_name}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs capitalize ">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {contact.last_name}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs">
                            <p className="text-gray-900 whitespace-pre-line">
                              {contact.email}
                            </p>
                            {contact.unsubscribed == "Yes" ? (
                              <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight text-[8.5pt]">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative">Unsubscribed</span>
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs capitalize max-w-[100px] ">
                            <p className="text-gray-900 whitespace-pre-line">
                              {contact.company_name}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs capitalize max-w-[140px] ">
                            <p className="text-gray-900 whitespace-pre-line">
                              {contact.job_title}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs">
                            <p className="text-gray-900 whitespace-pre-line">
                            {contact.employee_count}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs">
                            <p className="text-gray-900 whitespace-pre-line">
                            {contact.revenue}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs">
                            <p className="text-gray-900 whitespace-pre-line">
                            {contact.doc_name && (
                              <a
                              href={`https://dbms.buzz-nation.com/phpApi/contactDocuments/${contact.doc_name}`}
                              target="_blank"
                              >
                                <div className="rounded-lg p-1 bg-[#FEE2E2] w-10">
                                <IoAttachSharp color="#B91C1C" className="h-8 w-8"/>
                                </div>
                              </a>
                             )}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs max-w-[140px]">
                            <p className="text-gray-900 whitespace-pre-line">
                            {contact.remarks}
                            </p>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                      <td
                        className="px-2 py-2 border-b border-gray-200 bg-white  w-[150px] max-w-[150px] text-xs"
                        colSpan={8}
                      >
                        <p className="text-gray-900 whitespace-pre-line text-sm font-semibold text-center">
                          No Records Found
                        </p>
                      </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {totalPages > 1 && (
              <div className="mt-4 flex justify-end">
                <div>
                  <ul className="flex justify-center">
                    {!isFirstPage && (
                      <li
                        className="relative block rounded-2xl px-3 py-1.5 text-xl transition-all duration-300 hover:bg-neutral-100"
                        onClick={() =>
                          setCurrentPage((prev) => Math.max(prev - 1, 1))
                        }
                      >
                        <GrFormPrevious />
                      </li>
                    )}
                    {getPageNumbers().map((page, index) => (
                      <li
                        key={index}
                        className={`relative block rounded-2xl  px-3 py-1.5 text-xs  transition-all duration-300 ${
                          page === currentPage && page !== "..."
                            ? "bg-[#84cc16] text-white"
                            : "bg-transparent text-black hover:bg-neutral-100"
                        }`}
                        onClick={() => (page !== "..." ? paginate(page) : null)}
                      >
                        {page}
                      </li>
                    ))}
                    {!isLastPage && (
                      <li
                        className="relative block rounded-2xl px-3 py-1.5 text-xl transition-all duration-300 hover:bg-neutral-100"
                        onClick={() =>
                          setCurrentPage((prev) =>
                            Math.min(
                              prev + 1,
                              Math.ceil(searchedDataArray.length / itemsPerPage)
                            )
                          )
                        }
                      >
                        <GrFormNext />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Transition.Root show={currentModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => setCurrentModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Select Headers to Export
                        </Dialog.Title>
                        <div className="grid grid-cols-2 gap-4 mt-4">
                          {headers.map((header, index) => (
                            <div
                              key={index}
                              className="flex items-center capitalize"
                            >
                              <input
                                type="checkbox"
                                id={`header-${index}`}
                                className="mr-2 rounded-md focus:ring-green-500 text-green-600"
                                checked={checkedHeaders.includes(header)}
                                onChange={() => handleHeaderChange(header)}
                              />
                              <label htmlFor={`header-${index}`}>
                                {header}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-[#16a34a] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto  "
                      onClick={finalExport}
                    >
                      Export
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setCurrentModalOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
export default AccountFilters;
