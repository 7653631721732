import React from "react";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";

function Breadcrumbs1({ level1, level2, level1Path, level2Path }) {
  return (
    <div className="flex mx-auto max-w-7xl mt-2 mb-4">
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-2 ">
          <li className="inline-flex items-center">
            <Link
              to="/dashboard"
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-[#0b7764]"
            >
              <FaHome  />
            </Link>
          </li>
          {level1 ? (
            <li>
              <div className="flex items-center">
                <IoIosArrowForward />
                <Link
                  to={level1Path}
                  className="ms-1 text-xs font-medium text-gray-700 hover:text-[#0b7764] md:ms-2"
                >
                  {level1}
                </Link>
              </div>
            </li>
          ) : (
            ""
          )}

          {level2 ? (
            <li>
              <div className="flex items-center">
                <IoIosArrowForward />
                <Link
                  to={level2Path}
                  className="ms-1 text-xs font-medium text-gray-700 hover:text-[#0b7764] md:ms-2"
                >
                  {level2}
                </Link>
              </div>
            </li>
          ) : (
            ""
          )}
        </ol>
      </nav>
    </div>
  );
}

export default Breadcrumbs1;
