import Cards from "./Cards";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { RiContactsBook2Fill } from "react-icons/ri";
import { LuContact2 } from "react-icons/lu";
import { FaFileUpload } from "react-icons/fa";
import { HiFilter } from "react-icons/hi";
import { MdMarkEmailUnread } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { IoDocuments } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useEffect,useState } from "react";
import axios from "axios";

function Dashboard() {
  
  return (
    <div className="bg-white">
      <Navbar />
      <Sidebar />
      <div className="ml-[120px] mt-8">
        <div className="flex space-x-8">
          <Link to="/add_contact">
            <Cards
              icon={<LuContact2 fontSize="2em" color="#ffb34d" />}
              heading={"Add a Contact"}
              subtitle={"Add a contacts to the database"}
              background={"bg-[#fdedd6]"}
            />
          </Link>
          <Link to="/view_all_contact">
            <Cards
              icon={<RiContactsBook2Fill fontSize="2em" color="#368f8b" />}
              heading={"View All Contacts"}
              subtitle={"View all contacts present in database"}
              background={"bg-[#dbefea]"}
            />
          </Link>
          <Link to="/bulk_upload">
            <Cards
              icon={<FaFileUpload fontSize="2em" color="#78991a" />}
              heading={"Bulk Upload"}
              subtitle={"Add multiple contacts to the database"}
              background={"bg-[#edf9d2]"}
            />
          </Link>
        </div>
        <div className="flex space-x-8 mt-6">
          <Link to="/filters">
            <Cards
              icon={<HiFilter fontSize="2em" color="#78991a" />}
              heading={"Account Based Filters"}
              subtitle={"View contacts as per selection"}
              background={"bg-[#edf9d2]"}
            />
          </Link>
          <Link to="/upload_email_status">
            <Cards
              icon={<MdMarkEmailUnread fontSize="2em" color="#ffb34d" />}
              heading={"Upload Email Status"}
              subtitle={"Add multiple contacts Email Status"}
              background={"bg-[#fdedd6]"}
            />
          </Link>
          <Link to="/revenue_employee_data">
            <Cards
              icon={<RiMoneyDollarCircleFill fontSize="2em" color="#368f8b" />}
              heading={"Revenue Details and Employee Count"}
              subtitle={"Manage Account Based Revenue Data"}
              background={"bg-[#dbefea]"}
            />
          </Link>
        </div>
        <div className="flex space-x-8 mt-6">
        <Link to="/document_bulk_upload">
          <Cards
            icon={<IoDocuments fontSize="2em" color="#368f8b" />}
            heading={"Upload Documents"}
            subtitle={"Upload documents at once"}
            background={"bg-[#dbefea]"}
          />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
