import React, { useState, Fragment, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import axios from "axios";
import Swal from "sweetalert2";
import Breadcrumbs1 from "./breadcrumbs1";
import "react-datepicker/dist/react-datepicker.css";
import { MdEdit } from "react-icons/md";
import { FaSort, FaSearch } from "react-icons/fa";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import * as XLSX from "xlsx";

function RevenueEmployee() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedItems, setSelectedItems] = useState([]);

  const [openForm, setOpenForm] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [editForm, setEditForm] = useState(false);
  const [editFormData, setEditFormData] = useState({
    account_name: "",
    account_domain: "",
    revenue: "",
    employee_count: "",
  });

  const cancelButtonRef = useRef(null);

  const initialFormData = {
    Account_Name: "",
    Account_Domain: "",
    Revenue: "",
    Employee_Counts: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    getREInfo();
  }, []);

  const getREInfo = async () => {
    const dataArray = [];
    try {
      const dataResponse = await axios.get(
        `https://dbms.buzz-nation.com/phpApi/getREInfo.php`
      );
      if (dataResponse.status === 200) {
        const data = dataResponse.data.data;
        dataArray.push(...data);
      } else if (dataResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    }
    setDataArray(dataArray);
    setTotalRecords(dataArray.length);
  };

  const handleInfoSubmit = (e) => {
    e.preventDefault();

    if (!formData.Account_Name.trim() || !formData.Account_Domain.trim()) {
      Swal.fire({
        text: "Kindly Fill the Required Fields",
        icon: "warning",
      });
      return;
    }

    axios
      .post("https://dbms.buzz-nation.com/phpApi/insertREData.php", formData)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            text: "Data Inserted Successfully",
            icon: "success",
          });
          e.target.reset();
          getREInfo();
          setFormData(initialFormData);
          setOpenForm(false);
        }
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };

  const handleEventChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const openAddForm = () => {
    setOpenForm(true);
  };

  const handleEditStatus = (data) => {
    setEditFormData(data);
    setEditForm(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();

    if (
      !editFormData.account_name.trim() ||
      !editFormData.account_domain.trim()
    ) {
      Swal.fire({
        text: "Kindly Fill the Required Fields",
        icon: "warning",
      });
      return;
    }

    axios
      .post("https://dbms.buzz-nation.com/phpApi/updateREInfo.php", editFormData)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            text: "Data Updated Successfully",
            icon: "success",
          });
          getREInfo();
          setEditForm(false);
        }
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === Math.ceil(totalRecords / itemsPerPage);

  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  const getPageNumbers = () => {
    if (totalPages <= 5) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    let pages = [1];

    if (currentPage > 2) {
      pages.push("...");
    }

    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(currentPage + 1, totalPages - 1);
      i++
    ) {
      pages.push(i);
    }

    if (currentPage < totalPages - 2) {
      pages.push("...");
    }

    pages.push(totalPages);

    return pages;
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleItemsPerPageChange = (value) => {
    setCurrentPage(1); // Reset to the first page when changing items per page
    setItemsPerPage(value);
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = () => {
    const sortableData = [...dataArray];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  };

  let searchedDataArray = [];
  const filteredData = () => {
    const sortedDataArray = sortedData();
    searchedDataArray = sortedDataArray.filter((contact) =>
      Object.values(contact).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const currentItems = () => {
    filteredData();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Return the portion of data for the current page
    return searchedDataArray.slice(startIndex, endIndex);
  };

 
  function exportToExcel() {
    if (selectedItems.length == 0) {
      Swal.fire({
        text: "Kindly select data to export",
        icon: "warning",
      });
    } else {
      // Determine whether "Select All" is checked
      const isSelectAllChecked = selectedItems.length === dataArray.length;
  
      // Filter the data based on the selected items
      const selectedData = isSelectAllChecked
        ? dataArray
            .filter((contact) =>
              Object.values(contact).some((value) =>
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
              )
            )
            .map(({ id, ...contactWithoutId }) => contactWithoutId)
        : dataArray
            .filter((contact) => selectedItems.includes(contact.id))
            .map(({ id, ...contactWithoutId }) => contactWithoutId);
  
      const ws = XLSX.utils.json_to_sheet(selectedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Data Sheet");
  
      // Generate a unique file name
      const fileName = "data-export-" + new Date().getTime() + ".xlsx";
  
      XLSX.writeFile(wb, fileName);
    }
  }
  

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const allItemIds = dataArray.map((contact) => contact.id);
    setSelectedItems(isChecked ? allItemIds : []);
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        // If the item is already selected, remove it
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        // If the item is not selected, add it
        return [...prevSelectedItems, itemId];
      }
    });
  };
  return (
    <div className="bg-white">
      <Navbar />
      <Sidebar />
      <div className="ml-[120px] mt-4 mr-[40px]">
        <div>
          <Breadcrumbs1
            level1="Revenue & Employee Details"
            level1Path="/revenue_employee_data"
          />
        </div>
        <div className="flex">
          <div className="text-xl mt-[10px] font-semibold">
            <span>Revenue Details & Employee Counts </span>
          </div>

          <div className="flex ml-auto">
            <div className="flex items-center mr-4 gap-x-6">
              <button
                type="button"
                onClick={openAddForm}
                className="text-sm font-semibold rounded-md text-white bg-[#57534E] px-3 py-2 border border-1 border-black-600"
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="mt-4 flex">
          <div className=" flex items-center mr-4">
            <label className="inline-block mr-2 text-sm font-medium text-gray-900">
              Show Records:
            </label>
            <select
              className="border border-gray-300 rounded-full text-xs font-bold text-gray-600 pl-5 pr-8 bg-white hover:border-gray-400 focus:outline-none appearance-none"
              value={itemsPerPage}
              onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
          

          <div className="flex ml-auto mr-8">
            <div className="relative mr-4">
              <span className="absolute inset-y-0 left-0 px-2  flex items-center">
                <FaSearch className="text-gray-500" />
              </span>
              <input
                className="border border-gray-300 rounded-full text-xs font-semibold pl-8 pr-8 bg-white hover:border-gray-400 focus:outline-none"
                id="username"
                type="text"
                placeholder="Search Records"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>

            <div className="ml-4 mr-4">
              <button
                className="bg-[#16a34a] py-[7px] px-4 rounded text-xs text-white font-semibold -ml-4"
                onClick={exportToExcel}
              >
                Export
              </button>
            </div>

            {totalPages > 1 && (
              <div>
                <ul className="flex justify-center">
                  {!isFirstPage && (
                    <li
                      className="relative block rounded-2xl px-3 py-1.5 text-xl transition-all duration-300 hover:bg-neutral-100"
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                    >
                      <GrFormPrevious />
                    </li>
                  )}
                  {getPageNumbers().map((page, index) => (
                    <li
                      key={index}
                      className={`relative block rounded-2xl  px-3 py-1.5 text-xs  transition-all duration-300 ${
                        page === currentPage && page !== "..."
                          ? "bg-[#84cc16] text-white"
                          : "bg-transparent text-black hover:bg-neutral-100"
                      }`}
                      onClick={() => (page !== "..." ? paginate(page) : null)}
                    >
                      {page}
                    </li>
                  ))}
                  {!isLastPage && (
                    <li
                      className="relative block rounded-2xl px-3 py-1.5 text-xl transition-all duration-300 hover:bg-neutral-100"
                      onClick={() =>
                        setCurrentPage((prev) =>
                          Math.min(
                            prev + 1,
                            Math.ceil(dataArray.length / itemsPerPage)
                          )
                        )
                      }
                    >
                      <GrFormNext />
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="mt-4">
          <div className=" mt-4 space-y-12">
            <div className="inline-block min-w-[55%] shadow rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                  <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 w-[50px] max-w-[50px] tracking-wider">
                        <div className="flex flex-row">
                          <input
                            type="checkbox"
                            id="selectAll"
                            className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500"
                            checked={
                              selectedItems.length === dataArray.length
                            }
                            onChange={handleSelectAll}
                          />
                          <span className="ml-1">All</span>
                        </div>
                      </th>
                    <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 max-w-[300px]">
                      <div className="flex flex-row">
                        Account Name
                        <FaSort onClick={() => requestSort("account_name")} />
                      </div>
                    </th>
                    <th className="pl-2 pr-8 py-2 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 max-w-[300px] ">
                      <div className="flex flex-row">
                        Account Domain
                        <FaSort onClick={() => requestSort("account_domain")} />
                      </div>
                    </th>
                    <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 ">
                      <div className="flex flex-row">
                        Revenue (in million $)
                        <FaSort onClick={() => requestSort("revenue")} />
                      </div>
                    </th>
                    <th className="pl-2 pr-8 py-2 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 ">
                      <div className="flex flex-row">
                        Employee Count
                        <FaSort onClick={() => requestSort("employee_count")} />
                      </div>
                    </th>
                    <th className="pl-2 pr-8 py-2 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 ">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems().length > 0 ? (
                    currentItems().map((data) => (
                      <tr key={data.id}>
                         <td className="px-2 py-2 border-b border-gray-200 bg-white w-[30px] max-w-[30px] text-xs">
                            <p className="text-gray-900 whitespace-no-wrap">
                              <input
                                type="checkbox"
                                className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500"
                                checked={selectedItems.includes(data.id)}
                                onChange={() =>
                                  handleCheckboxChange(data.id)
                                }
                              />
                            </p>
                          </td>
                        <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm max-w-[300px] text-center">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {data.account_name}
                          </p>
                        </td>
                        <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm max-w-[300px] text-center">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {data.account_domain}
                          </p>
                        </td>
                        <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm text-center">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {data.revenue}
                          </p>
                        </td>
                        <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm text-center">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {data.employee_count}
                          </p>
                        </td>
                        <td className="pl-2 pr-2 py-2 border-b border-gray-00 bg-white text-xs">
                          <button
                            className="bg-[#ECFCCB] text-white px-1 py-1 font-bold rounded-full"
                            onClick={() => handleEditStatus(data)}
                          >
                            <MdEdit fontSize="2em" color="#22C55E" />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center py-2">
                        No Details found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="mt-4 flex">
          <div className="ml-auto mr-8">
          {totalPages > 1 && (
              <div>
                <ul className="flex justify-center">
                  {!isFirstPage && (
                    <li
                      className="relative block rounded-2xl px-3 py-1.5 text-xl transition-all duration-300 hover:bg-neutral-100"
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                    >
                      <GrFormPrevious />
                    </li>
                  )}
                  {getPageNumbers().map((page, index) => (
                    <li
                      key={index}
                      className={`relative block rounded-2xl  px-3 py-1.5 text-xs  transition-all duration-300 ${
                        page === currentPage && page !== "..."
                          ? "bg-[#84cc16] text-white"
                          : "bg-transparent text-black hover:bg-neutral-100"
                      }`}
                      onClick={() => (page !== "..." ? paginate(page) : null)}
                    >
                      {page}
                    </li>
                  ))}
                  {!isLastPage && (
                    <li
                      className="relative block rounded-2xl px-3 py-1.5 text-xl transition-all duration-300 hover:bg-neutral-100"
                      onClick={() =>
                        setCurrentPage((prev) =>
                          Math.min(
                            prev + 1,
                            Math.ceil(dataArray.length / itemsPerPage)
                          )
                        )
                      }
                    >
                      <GrFormNext />
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        
        </div>
        <Transition.Root show={openForm} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={() => setOpenForm(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            Add Revenue Details & Employee Counts
                          </Dialog.Title>
                          <div className="mt-4">
                            <form
                              onSubmit={handleInfoSubmit}
                              encType="multipart/form-data"
                            >
                              <div className="space-y-12">
                                <div className="border-b border-gray-900/10 pb-8">
                                  <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                    <div className="sm:col-span-6">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        <span className="text-red-500">*</span>
                                        Account Name
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          name="Account_Name"
                                          // value={eventFormData.Event_Name}
                                          onChange={handleEventChange}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:col-span-6">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        <span className="text-red-500">*</span>
                                        Account Domain 
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          name="Account_Domain"
                                          // value={eventFormData.Event_Name}
                                          onChange={handleEventChange}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                      <p className="text-xs text-red-500">Kindly use comma(,) to enter multiple domain</p>
                                    </div>
                                    <div className="sm:col-span-3">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Revenue (in million $)
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="number"
                                          name="Revenue"
                                          // value={eventFormData.Event_Name}
                                          onChange={handleEventChange}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Employee Counts
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="number"
                                          name="Employee_Counts"
                                          // value={eventFormData.Event_Name}
                                          onChange={handleEventChange}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="py-3 sm:flex sm:flex-row-reverse">
                                <button
                                  type="submit"
                                  className="inline-flex w-full justify-center rounded-md bg-[#16a34a] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto  "
                                >
                                  Submit
                                </button>
                                <button
                                  type="button"
                                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                  onClick={() => setOpenForm(false)}
                                  ref={cancelButtonRef}
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={editForm} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={() => setEditForm(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            Edit Revenue Details & Employee Counts
                          </Dialog.Title>
                          <div className="mt-4">
                            <form
                              onSubmit={handleEditSubmit}
                              encType="multipart/form-data"
                            >
                              <div className="space-y-12">
                                <div className="border-b border-gray-900/10 pb-8">
                                  <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                    <div className="sm:col-span-6">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        <span className="text-red-500">*</span>
                                        Account Name
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          name="account_name"
                                          value={editFormData.account_name}
                                          onChange={handleEditChange}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:col-span-6">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        <span className="text-red-500">*</span>
                                        Account Domain
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          name="account_domain"
                                          value={editFormData.account_domain}
                                          onChange={handleEditChange}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                      <p className="text-xs text-red-500">Kindly use comma(,) to enter multiple domain</p>
                                    </div>
                                    <div className="sm:col-span-3">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Revenue (in million $)
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="number"
                                          name="revenue"
                                          value={editFormData.revenue}
                                          onChange={handleEditChange}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Employee Counts
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="number"
                                          name="employee_count"
                                          value={editFormData.employee_count}
                                          onChange={handleEditChange}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="py-3 sm:flex sm:flex-row-reverse">
                                <button
                                  type="submit"
                                  className="inline-flex w-full justify-center rounded-md bg-[#16a34a] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto  "
                                >
                                  Update
                                </button>
                                <button
                                  type="button"
                                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                  onClick={() => setEditForm(false)}
                                  ref={cancelButtonRef}
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}

export default RevenueEmployee;
