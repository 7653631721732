import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import buzzLogo from "../images/buzzLogo.jpg";
import dbmsImg from "../images/dbms.jpg";
import { useEffect } from "react";
import Swal from "sweetalert2";

function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [welcomeText, setWelcomeText] = useState(
    "Welcome back! Please enter your details"
  );
  const [ip, setIP] = useState("");
  const [action, setAction] = useState(1);

  // console.log(ipAddress);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    let login = localStorage.getItem("loginToken");
    if (login) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    getData();
  }, [ip]);

  const getData = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      setIP(res.data.ip);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error appropriately
    }
  };

  const checkInUser = () => {
    let userName = localStorage.getItem("userName")
    const formData = {
      ip: ip,
      userName: userName,
      action: action,
    };
    axios
      .post("https://dbms.buzz-nation.com/phpApi/insertUserLogData.php", formData)
      .then((response) => {
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };

  const handleSubmit = async () => {
    try {
      setError("");
   
      if (!username && !password) {
        setError("Username and password cannot be empty");
        setWelcomeText("Username and password cannot be empty");
        return; // Exit the function if fields are empty
      } else if (!username) {
        setError("Username cannot be empty");
        setWelcomeText("Username cannot be empty");
        return;
      } else if (!password) {
        setError("Password cannot be empty");
        setWelcomeText("Password cannot be empty");
        return;
      }
      // Make an API request using Axios
      const response = await axios.post(
        "https://dbms.buzz-nation.com/phpApi/checkUser.php",
        {
          username: username.toLowerCase(),
          password: password,
        }
      );
      if (response.status == 200) {
        localStorage.setItem("login", true);
        localStorage.setItem("loginToken", response.data.token);
        localStorage.setItem("userName", username);
          checkInUser();
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Incorrect Email or Password.");
      setWelcomeText("Incorrect Email or Password.");
    }
  };

  const handleForgotPassword = () => {
    Swal.fire({
      html: "Kindly login using the official credentials.<br>If you have forgot your password kindly contact Admin",
      icon: "info",
      timer: 5000
    });
    return;
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0">
        <div className="flex flex-col justify-center  md:px-14 pb-4">
          <div className="flex flex-row items-center justify-center">
            <div className="flex h-[120px] w-[120px] object-cover">
              <img alt="" className="h-full w-full" src={buzzLogo} />
            </div>
          </div>
          <span className="mb-3 text-3xl font-bold ">
            Database Management System
          </span>
          <span
            className={
              error
                ? "text-red-500 font-semibold mb-4"
                : "font-light text-gray-400 mb-4"
            }
          >
            {welcomeText}
          </span>
          <div className="py-3">
            <span className="mb-2 text-md font-bold">
              <span className=" text-red-600">*</span> Email
            </span>
            <input
              type="text"
              className="w-full p-2 mt-2 border border-gray-300 rounded-md placeholder:font-light placeholder:text-gray-500"
              name="email"
              id="email"
              value={username}
              onChange={handleUsernameChange}
            />
          </div>
          <div className="py-3">
            <span className="mb-2 text-md font-bold">
              <span className=" text-red-600">*</span> Password
            </span>
            <input
              type="password"
              name="pass"
              id="pass"
              value={password}
              onChange={handlePasswordChange}
              className="w-full p-2 mt-2 border border-gray-300 rounded-md placeholder:font-light placeholder:text-gray-500"
            />
          </div>
          <div className="flex justify-end w-full py-2">
            <span className="font-bold text-md text-[#278977] cursor-pointer">
              <a onClick={handleForgotPassword}>Forgot password?</a>
            </span>
          </div>
          <button
            className="w-full h-[40px] bg-[#278977] text-white p-2 mt-4 rounded-lg mb-6 hover:bg-[#429684]"
            onClick={handleSubmit}
          >
            Sign in
          </button>
        </div>
        <div className="relative">
          <img
            src={dbmsImg}
            alt="img"
            className="w-[450px] h-full hidden rounded-r-2xl md:block object-cover"
          />
          {/* <div
              className="absolute hidden top-10 right-6 p-6 bg-white bg-opacity-30 backdrop-blur-sm rounded drop-shadow-lg md:block"
            >
              <span className="text-white text-2xl"
                >Database Management System
              </span>
            </div> */}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
