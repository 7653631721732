import React, { useState, useEffect,Fragment, useRef } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { FaSort, FaSearch } from "react-icons/fa";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import SpinningIcon from "./icon/SpinningIcon";
import { IoIosArrowDown } from "react-icons/io";
import Breadcrumbs1 from "./breadcrumbs1";
import Swal from "sweetalert2";
import { MdOutlineClear } from "react-icons/md";
import { IoAttachSharp } from "react-icons/io5";
import { Dialog, Transition } from "@headlessui/react";
function ViewContact() {
  const [contactData, setContactData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [groupSearchTerm, setGroupSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [GroupNamesDataArray, setGroupNamesDataArray] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isEmailDropdownOpen, setEmailDropdownOpen] = useState(false);
  const [emailName, setEmailName] = useState('');
  const [emailNamesDataArray, setEmailNamesDataArray] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [emailSearchTerm, setEmailSearchTerm] = useState("");
  const [selectedEmailStatusName, setSelectedEmailStatusName] = useState([]);
  const [isEmailStatusDropdownOpen,setEmailStatusDropdownOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const [unsubscribedCount,setUnsubscribedCount] = useState(0);
  const [bouncedCount,setBouncedCount] = useState(0);
  const navigate = useNavigate();

  const [currentModalOpen, setCurrentModalOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [headers, setHeaders] = useState([]);
  const [checkedHeaders, setCheckedHeaders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const postData = {
        selectedGroup : selectedGroup,
        selectedEmail : selectedEmail,
        selectedEmailStatus : selectedEmailStatusName
      }
      try {
        const response = await axios.post(
          `https://dbms.buzz-nation.com/phpApi/getData.php`,postData
        );console.log(response);
        if (response.status === 200) {
          setContactData(response.data.data);
          getGroupNames();
          getEmailNames();
          getUnsubscribedCount();
          getBouncedCount();
        } else if (response.status === 404) {
          setContactData([]);
        } else {
          console.log("No Data Fetched");
        }        
      } catch (error) {
        setContactData([]);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    setCurrentPage(1);

    if (selectedGroup.length > 0 || selectedEmail.length > 0 || selectedEmailStatusName.length > 0 ) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [selectedEmail, selectedGroup,selectedEmailStatusName]);

  const closeAllDropdownsExcept = (exceptDropdown) => {
    setDropdownOpen(false);
    setEmailDropdownOpen(false);
    setEmailStatusDropdownOpen(false);

    // Set the specified dropdown to true
    switch (exceptDropdown) {
      case "group":
        setDropdownOpen(true);
        break;
      case "email":
        setEmailDropdownOpen(true);
        break;
      case "emailStatus":
        setEmailStatusDropdownOpen(true);
        break;
      default:
        break;
    }
  };

  const getGroupNames = async () => {
    const GroupNamesArray = [];
    try {
      const groupNameResponse = await axios.get(
        `https://dbms.buzz-nation.com/phpApi/getGroupData.php?`
      );
      if (groupNameResponse.status === 200) {
        const groupNameResponseData = groupNameResponse.data.data;
        const groupNameValues = groupNameResponseData.map((item) => ({
          group_name: item.group_name,
          group_name_count: item.group_name_count,
        }));
        const validGroupValues = groupNameValues.filter(
          (value) =>
            value.group_name.trim() !== "" &&
            value.group_name_count.trim() !== ""
        );
        const strAscending = [...validGroupValues].sort((a, b) =>
          a.group_name > b.group_name ? 1 : -1
        );
        // const groupNameValues = groupNameResponseData.map(
        //   (item) => item.group_name,

        // );
        GroupNamesArray.push(...strAscending);
      } else if (groupNameResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    }

    // Set the array in state
    setGroupNamesDataArray(GroupNamesArray);
  };

  const getEmailNames = async () => {
    const emailNamesArray = [];
    try {
      const emailNameResponse = await axios.get(
        `https://dbms.buzz-nation.com/phpApi/getEmailNamesData.php`
      );
      if (emailNameResponse.status === 200) {
        const emailNameResponseData = emailNameResponse.data.data;
        const emailNameValues = emailNameResponseData.map((item) => ({
          email_name: item.email_name,
          email_name_count: item.email_name_count,
        }));
        const validEmailValues = emailNameValues.filter(
          (value) =>
            value.email_name.trim() !== "" &&
            value.email_name_count.trim() !== ""
        );
        const strAscending = [...validEmailValues].sort((a, b) =>
          a.email_name > b.email_name ? 1 : -1
        );
        // const emailNameValues = emailNameResponseData.map(
        //   (item) => item.email_name
        // );
        emailNamesArray.push(...strAscending);
      } else if (emailNameResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    }

    // Set the array in state
    setEmailNamesDataArray(emailNamesArray);
  };

  const getUnsubscribedCount = async () => {
    try {
      const countResponse = await axios.get(
        `https://dbms.buzz-nation.com/phpApi/getUnsubscribedCount.php`
      );
      if (countResponse.status === 200) {
        setUnsubscribedCount(countResponse.data.data.unsubscribed_count);
      }else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    }

    // Set the array in state
  };

  const getBouncedCount = async () => {
    try {
      const countResponse = await axios.get(
        `https://dbms.buzz-nation.com/phpApi/getBouncedCount.php`
      );
      if (countResponse.status === 200) {
        setBouncedCount(countResponse.data.data.bounced_count);
      }else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    }
  };



  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = () => {
    const sortableData = [...contactData];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  let filteredDataArray = [];

  const filteredData = () => {
    const sortedDataArray = sortedData();

    // Apply searching
    const searchedDataArray = sortedDataArray.filter((contact) =>
      Object.values(contact).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    filteredDataArray = searchedDataArray;
  };

  const currentItems = () => {
    filteredData();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Return the portion of data for the current page
    return filteredDataArray.slice(startIndex, endIndex);
  };

  useEffect(() => {
    setTotalRecords(filteredDataArray.length);
  }, [filteredDataArray]);

  const handleItemsPerPageChange = (value) => {
    setCurrentPage(1); // Reset to the first page when changing items per page
    setItemsPerPage(value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === Math.ceil(totalRecords / itemsPerPage);

  const totalPages = Math.ceil(totalRecords / itemsPerPage);


  const getPageNumbers = () => {
    if (totalPages <= 5) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
  
    let pages = [1];
  
    if (currentPage > 2) {
      pages.push("...");
    }
  
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(currentPage + 1, totalPages - 1);
      i++
    ) {
      pages.push(i);
    }
  
    if (currentPage < totalPages - 2) {
      pages.push("...");
    }
  
    pages.push(totalPages);
  
    // Adjust the pages array to show "1 2 ... lastpage"
    if (pages[1] === "..." && pages[2] === 2) {
      pages.splice(1, 1);
    }
  
    if (pages[pages.length - 2] === "..." && pages[pages.length - 3] === totalPages - 1) {
      pages.splice(pages.length - 2, 1);
    }
  
    return pages;
  };
 
  function exportToExcel() {
    if (selectedItems.length == 0) {
      Swal.fire({
        text: "Kindly select data to export",
        icon: "warning",
      });
    } else {
      setCurrentModalOpen(true);
      const headers = Object.keys(contactData[0]);
      const headersWithoutIdAndDocName = headers.filter(
        (key) => key !== "id" && key !== "doc_name" && key !== "created_date" && key !== "modified_date"
      );

      setHeaders(headersWithoutIdAndDocName);
    }
  }

  
  const handleSearchInputChange = (f) => {
    setGroupSearchTerm(f.target.value.toLowerCase());
  };

  const handleOptionClick = (selectedGroupName) => {
    const isSelected = selectedGroup.includes(selectedGroupName);
  
    if (isSelected) {
      setSelectedGroup((prevSelectedGroup) =>
      prevSelectedGroup.filter((group) => group !== selectedGroupName)
      );
    } else {
      setSelectedGroup((prevSelectedGroup) => [...prevSelectedGroup, selectedGroupName]);
    }
  
    setDropdownOpen(false); // Close the dropdown after selecting an option
  };

  const handleDropdownButtonClick = () => {
    setDropdownOpen((prevIsDropdownOpen) => !prevIsDropdownOpen);
    
    // Close other dropdowns if the current dropdown is opened
    if (!isDropdownOpen) {
      closeAllDropdownsExcept("group");
    }
  };
  

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const allItemIds = contactData.map((contact) => contact.id);
    setSelectedItems(isChecked ? allItemIds : []);
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
  };


  const handleEmailDropdownButtonClick = () => {
    setEmailDropdownOpen((prevIsEmailDropdownOpen) => !prevIsEmailDropdownOpen);
    if (!isEmailDropdownOpen) {
      closeAllDropdownsExcept("email");
    }
  };
  

  const handleEmailOptionClick = (selectedEmailName) => {
    const isSelected = selectedEmail.includes(selectedEmailName);
  
    if (isSelected) {
      setSelectedEmail((prevSelectedEmail) =>
      prevSelectedEmail.filter((email) => email !== selectedEmailName)
      );
    } else {
      setSelectedEmail((prevSelectedEmail) => [...prevSelectedEmail, selectedEmailName]);
    }
  
    setEmailDropdownOpen(false); // Close the dropdown after selecting an option
  };
  const handleEmailSearchInputChange = (f) => {
    setEmailSearchTerm(f.target.value.toLowerCase());
  };

  const handleEmailStatusOptionClick  = (selectedEmailStatus) => {
    const isSelected = selectedEmailStatusName.includes(selectedEmailStatus);
  
    if (isSelected) {
      setSelectedEmailStatusName((prevSelectedEmailStatus) =>
      prevSelectedEmailStatus.filter((emailStatus) => emailStatus !== selectedEmailStatus)
      );
    } else {
      setSelectedEmailStatusName((prevSelectedEmailStatus) => [...prevSelectedEmailStatus, selectedEmailStatus]);
    }
  
    setEmailStatusDropdownOpen(false); 
  };

  const handleEmailStatusDropdownButtonClick = () => {
    setEmailStatusDropdownOpen((prevIsEmailStatusDropdownOpen) => !prevIsEmailStatusDropdownOpen);
    if (!isEmailStatusDropdownOpen) {
      closeAllDropdownsExcept("emailStatus");
    }
  };

  const resetFilters = () => {
    setSelectedGroup([]);
    setSelectedEmail([]);
    setSelectedEmailStatusName([]);
    closeAllDropdownsExcept('');
  };
  
  const handleRemoveGroup = (index) => {
    const updatedGroup = [...selectedGroup];
    updatedGroup.splice(index, 1);
    setSelectedGroup(updatedGroup);
  };

  const handleRemoveEmail = (index) => {
    const updatedEmail = [...selectedEmail];
    updatedEmail.splice(index, 1);
    setSelectedEmail(updatedEmail);
  };

  const handleRemoveEmailStatus = (index) => {
    const updatedEmailStatus = [...selectedEmailStatusName];
    updatedEmailStatus.splice(index, 1);
    setSelectedEmailStatusName(updatedEmailStatus);
  };

  const handleHeaderChange = (header) => {
    const isSelected = checkedHeaders.includes(header);
    if (isSelected) {
      setCheckedHeaders((prev) => prev.filter((item) => item !== header));
    } else {
      setCheckedHeaders((prev) => [...prev, header]);
    }
  };

  const finalExport = () => {
    if (checkedHeaders.length == 0) {
      Swal.fire({
        text: "Kindly select atleast one header to export",
        icon: "warning",
      });
    } else {
      setCurrentModalOpen(false);
      const isSelectAllChecked = selectedItems.length === contactData.length;

      const selectedData = isSelectAllChecked
        ? contactData.map((entry) =>
            checkedHeaders.reduce((acc, header) => {
              acc[header] = entry[header];
              return acc;
            }, {})
          )
        : contactData
            .filter((contact) => selectedItems.includes(contact.id))
            .map((entry) =>
              checkedHeaders.reduce((acc, header) => {
                acc[header] = entry[header]; // Include selected header and its value
                return acc;
              }, {})
            );

      const ws = XLSX.utils.json_to_sheet(selectedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Data Sheet");

      // Generate a unique file name
      const fileName = "data-export-" + new Date().getTime() + ".xlsx";

      XLSX.writeFile(wb, fileName);
    }
  };
  return (
    <div className="bg-white">
      <Navbar />
      <Sidebar />
      <div className="ml-[120px] mt-4 mr-[20px]">
        <div className="-ml-[16px]">
          <Breadcrumbs1
            level1="View All Contacts"
            level1Path="/view_all_contact"
          />
        </div>
        <div className="flex mt-4">
          <div className="text-xl font-semibold -ml-4">
            <span> View All Contacts</span>
          </div>
        </div>
        <div className="mt-4 flex">
          <div className=" flex items-center mr-4 -ml-[12px]">
            <label className="inline-block mr-2 text-sm font-medium text-gray-900">
              Show Records:
            </label>
            <select
              className="border border-gray-300 rounded-full text-xs font-bold text-gray-600 pl-5 pr-8 bg-white hover:border-gray-400 focus:outline-none appearance-none"
              value={itemsPerPage}
              onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div className="flex ml-auto">
            <div className="relative mr-4">
              <span className="absolute inset-y-0 left-0 px-2  flex items-center">
                <FaSearch className="text-gray-500" />
              </span>
              <input
                className="border border-gray-300 rounded-full text-xs font-semibold pl-8 pr-8 bg-white hover:border-gray-400 focus:outline-none"
                id="username"
                type="text"
                placeholder="Search Records"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>

            <div className="ml-4 mr-4">
              <button
                className="bg-[#16a34a] py-[7px] px-4 rounded text-xs text-white font-semibold -ml-4"
                onClick={exportToExcel}
              >
                Export
              </button>
            </div>

            {totalPages > 1 && (
              <div>
                <ul className="flex justify-center">
                  {!isFirstPage && (
                    <li
                      className="relative block rounded-2xl px-3 py-1.5 text-xl transition-all duration-300 hover:bg-neutral-100"
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                    >
                      <GrFormPrevious />
                    </li>
                  )}
                  {getPageNumbers().map((page, index) => (
                    <li
                      key={index}
                      className={`relative block rounded-2xl  px-3 py-1.5 text-xs  transition-all duration-300 ${
                        page === currentPage && page !== "..."
                          ? "bg-[#84cc16] text-white"
                          : "bg-transparent text-black hover:bg-neutral-100"
                      }`}
                      onClick={() => (page !== "..." ? paginate(page) : null)}
                    >
                      {page}
                    </li>
                  ))}
                  {!isLastPage && (
                    <li
                      className="relative block rounded-2xl px-3 py-1.5 text-xl transition-all duration-300 hover:bg-neutral-100"
                      onClick={() =>
                        setCurrentPage((prev) =>
                          Math.min(
                            prev + 1,
                            Math.ceil(contactData.length / itemsPerPage)
                          )
                        )
                      }
                    >
                      <GrFormNext />
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="mt-4 flex -ml-[12px]"> 
        <div className="mr-2">
            <div className="">
              <button
                id="dropdown-button"
                onClick={handleDropdownButtonClick}
                className="border border-gray-300 py-2 rounded-full flex items-center text-xs font-bold text-gray-600 pl-5 pr-3 bg-white hover:border-gray-400 focus:outline-none appearance-none"
              >
                <span className="mr-2">
                  {"All Contact Group"}
                </span>
                <IoIosArrowDown fontSize="12px" color="" />
              </button>
              {isDropdownOpen && (
                <div className="relative">
                <div
                  id="dropdown-menu"
                  className="absolute z-10 top-1 left-0 mt-2 w-[200px] max-h-[200px] overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-2 space-y-1"
                >
                  <input
                    id="search-input"
                    className="block w-full py-[1px] px-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none placeholder:text-xs placeholder:font-medium"
                    type="text"
                    placeholder="Search Contact Group"
                    value={groupSearchTerm}
                    onChange={handleSearchInputChange}
                  />
                  {GroupNamesDataArray.filter((groupName) =>
                    groupName.group_name.toLowerCase().includes(groupSearchTerm)
                  ).map((groupName, index) => (
                    <div key={index} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedGroup.includes(groupName.group_name)}
                      onChange={() =>
                        handleOptionClick(groupName.group_name)
                      }
                      className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                    />
                    <a
                      className="block px-1 py-1 text-gray-700 text-xs font-medium hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleOptionClick(groupName.group_name)}
                    >
                       {groupName.group_name}({groupName.group_name_count})
                    </a>
                  </div>
                  ))}
                </div>
                </div>
              )}
            </div>
          </div>
          <div className="mx-2">
            <div className="relative group">
              <button
                id="dropdown-button"
                onClick={handleEmailDropdownButtonClick}
                className="border border-gray-300 py-2 rounded-full flex items-center text-xs font-bold text-gray-600 pl-5 pr-3 bg-white hover:border-gray-400 focus:outline-none appearance-none"
              >
                <span className="mr-2">
                  {"All Email Name"}
                </span>
                <IoIosArrowDown fontSize="12px" color="" />
              </button>

              {isEmailDropdownOpen && (
                <div
                  id="dropdown-menu"
                  className="absolute z-10 top-8 left-0 mt-2 w-[200px] max-h-[200px] overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-2 space-y-1"
                >
                  <input
                    id="search-input"
                    className="block w-full py-[1px] px-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none placeholder:text-xs placeholder:font-medium"
                    type="text"
                    placeholder="Search Email Names"
                    value={emailSearchTerm}
                    onChange={handleEmailSearchInputChange}
                  />
                  {emailNamesDataArray
                    .filter((emailName) =>
                      emailName.email_name.toLowerCase().includes(emailSearchTerm)
                    )
                    .map((emailName, index) => (
                      <div key={index} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedEmail.includes(emailName.email_name)}
                      onChange={() =>
                        handleEmailOptionClick(emailName.email_name)
                      }
                      className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                    />
                      <a
                        className="block px-2 py-1 text-gray-700 text-xs font-medium hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleEmailOptionClick(emailName.email_name)}
                      >
                        {emailName.email_name}({emailName.email_name_count})
                      </a>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div className="mx-2">
            <div className="relative group">
              <button
                id="dropdown-button"
                onClick={handleEmailStatusDropdownButtonClick}
                className="border border-gray-300 py-2 rounded-full flex items-center text-xs font-bold text-gray-600 pl-5 pr-3 bg-white hover:border-gray-400 focus:outline-none appearance-none"
              >
                <span className="mr-2">
                  {"All Email Status"}
                </span>
                <IoIosArrowDown fontSize="12px" color="" />
              </button>

              {isEmailStatusDropdownOpen && (
                <div
                  id="dropdown-menu"
                  className="absolute z-10 top-8 left-0 mt-2 w-[200px] max-h-[200px] overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-2 space-y-1"
                >
                <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedEmailStatusName.includes('Unsubscribed')}
                      onChange={() =>
                        handleEmailStatusOptionClick('Unsubscribed')
                      }
                      className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                    />
                      <a
                        className="block px-2 py-1 text-gray-700 text-xs font-medium hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleEmailStatusOptionClick('Unsubscribed')}
                      >
                        Unsubscribed({unsubscribedCount})
                      </a>
                </div>
                <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedEmailStatusName.includes('Bounced')}
                      onChange={() =>
                        handleEmailStatusOptionClick('Bounced')
                      }
                      className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                    />
                      <a
                        className="block px-2 py-1 text-gray-700 text-xs font-medium hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleEmailStatusOptionClick('Bounced')}
                      >
                        Bounced({bouncedCount})
                      </a>
                </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-2 -ml-4 flex flex-wrap">
            {/* clear */}
            {showButton && (
              <div className="mx-2 mt-1">
                  <button
                    id="dropdown-button"
                    onClick={resetFilters}
                    className="border border-gray-300 py-2 rounded-full flex items-center text-xs font-bold text-white pl-5 pr-3 bg-[#84CC16] hover:border-gray-400 focus:outline-none appearance-none"
                  >
                    <span className="mr-2">Clear Filters</span>
                    <MdOutlineClear fontSize="12px" color="" />
                  </button>
              </div>
            )}
            {selectedGroup.length > 0 && selectedGroup.map((group, index) => (
                <div className="mx-1 mt-1" key={index}>
                  <button
                    id="dropdown-button"
                    onClick={() => handleRemoveGroup(index)}
                    className="border border-[#4D7C0F] py-2 rounded-full flex items-center text-xs font-bold text-[#4D7C0F] pl-5 pr-3 bg-[#F7FEE7] appearance-none"
                  >
                    <span className="mr-2">{group}</span>
                    <MdOutlineClear fontSize="12px" color="" />
                  </button>
                </div>
              ))}
              {selectedEmail.length > 0 && selectedEmail.map((email, index) => (
                <div className="mx-1 mt-1" key={index}>
                  <button
                    id="dropdown-button"
                    onClick={() => handleRemoveEmail(index)}
                    className="border border-[#4D7C0F] py-2 rounded-full flex items-center text-xs font-bold text-[#4D7C0F] pl-5 pr-3 bg-[#F7FEE7] appearance-none"
                  >
                    <span className="mr-2">{email}</span> 
                    <MdOutlineClear fontSize="12px" color="" />
                  </button>
                </div>
              ))}  
              {selectedEmailStatusName.length > 0 && selectedEmailStatusName.map((emailStatus, index) => (
                <div className="mx-1 mt-1" key={index}>
                  <button
                    id="dropdown-button"
                    onClick={() => handleRemoveEmailStatus(index)}
                    className="border border-[#4D7C0F] py-2 rounded-full flex items-center text-xs font-bold text-[#4D7C0F] pl-5 pr-3 bg-[#F7FEE7] appearance-none"
                  >
                    <span className="mr-2">{emailStatus}</span> 
                    <MdOutlineClear fontSize="12px" color="" />
                  </button>
                </div>
              ))}                
        </div>
        <div className="mt-2">
          <div className="-ml-[12px] -mb-2 mr-10">
            <span className=" text-xs font-semibold text-gray-700">
              {totalRecords ? totalRecords : 0} records found
            </span>
          </div>
        </div>
        <div className="-ml-[20px]">
          <div className="py-4 overflow-x-auto">
            {loading ? (
              // Show a loading icon or message while data is being fetched
              <div className="flex justify-center items-center">
                <SpinningIcon />
              </div>
            ) : (
              <div className="ml-2 inline-block min-w-[99%] shadow rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          <input
                            type="checkbox"
                            id="selectAll"
                            className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500"
                            checked={
                              selectedItems.length === contactData.length
                            }
                            onChange={handleSelectAll}
                          />
                          <span className="ml-1">All</span>
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          Source Id
                          {/* <FaSort onClick={() => requestSort("id")} /> */}
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          List Type
                          <FaSort onClick={() => requestSort("list_type")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          First Name
                          <FaSort onClick={() => requestSort("first_name")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          Last Name
                          <FaSort onClick={() => requestSort("last_name")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          Email Address <FaSort onClick={() => requestSort("email")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          Company
                          <FaSort onClick={() => requestSort("company_name")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          Title
                          <FaSort onClick={() => requestSort("job_title")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 ">
                        <div className="flex flex-row">
                          Employee Count
                          <FaSort onClick={() => requestSort("employee_count")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          Revenue
                          <FaSort onClick={() => requestSort("revenue")} />
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                          Attachment
                          {/* <FaSort onClick={() => requestSort("created_date")} /> */}
                        </div>
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700">
                        <div className="flex flex-row">
                         Remarks
                          <FaSort onClick={() => requestSort("remarks")} />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems().length > 0 ? (
                      currentItems().map((contact) => (
                        <tr key={contact.id}>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs">
                            <p className="text-gray-900 whitespace-no-wrap">
                              <input
                                type="checkbox"
                                className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500"
                                checked={selectedItems.includes(contact.id)}
                                onChange={() =>
                                  handleCheckboxChange(contact.id)
                                }
                              />
                            </p>
                          </td>
                          <td
                            className="cursor-pointer px-2 py-2 border-b border-gray-200 bg-white text-xs"
                            onClick={() => {
                              navigate(
                                `/details/view_all_contact/${contact.id}`
                              );
                            }}
                          >
                            <div className="flex">
                              <div className="ml-1">
                                <p className="text-gray-900 font-bold whitespace-no-wrap hover:text-[#84cc16]">
                                  {contact.id}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs capitalize max-w-[100px] ">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {contact.list_type}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs capitalize ">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {contact.first_name}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs capitalize ">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {contact.last_name}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs">
                            <p className="text-gray-900 whitespace-pre-line">
                              {contact.email}
                            </p>
                            {contact.unsubscribed == "Yes" ? (
                              <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight text-[8.5pt]">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative">Unsubscribed</span>
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs capitalize max-w-[100px] ">
                            <p className="text-gray-900 whitespace-pre-line">
                              {contact.company_name}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs capitalize max-w-[140px] ">
                            <p className="text-gray-900 whitespace-pre-line">
                              {contact.job_title}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs">
                            <p className="text-gray-900 whitespace-pre-line">
                            {contact.employee_count}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs">
                            <p className="text-gray-900 whitespace-pre-line">
                            {contact.revenue}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs text-center">
                            <p className="text-gray-900 whitespace-pre-line">
                             {contact.doc_name && (
                              <a
                              href={`https://dbms.buzz-nation.com/phpApi/contactDocuments/${contact.doc_name}`}
                              target="_blank"
                              >
                                <div className="rounded-lg p-1 bg-[#FEE2E2] w-10">
                                <IoAttachSharp color="#B91C1C" className="h-8 w-8"/>
                                </div>
                              </a>
                             )}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-xs max-w-[140px]">
                            <p className="text-gray-900 whitespace-pre-line">
                            {contact.remarks}
                            </p>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                      <td
                        className="px-2 py-2 border-b border-gray-200 bg-white text-xs"
                        colSpan={12}
                      >
                        <p className="text-gray-900 whitespace-no-wrap text-center font-semibold text-sm">
                          No Records Found
                        </p>
                      </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {totalPages > 1 && (
              <div className="mt-4 flex justify-end ">
                <div>
                  <ul className="flex justify-center">
                    {!isFirstPage && (
                      <li
                        className="relative block rounded-2xl px-3 py-1.5 text-xl transition-all duration-300 hover:bg-neutral-100"
                        onClick={() =>
                          setCurrentPage((prev) => Math.max(prev - 1, 1))
                        }
                      >
                        <GrFormPrevious />
                      </li>
                    )}
                    {getPageNumbers().map((page, index) => (
                      <li
                        key={index}
                        className={`relative block rounded-2xl  px-3 py-1.5 text-xs  transition-all duration-300 ${
                          page === currentPage && page !== "..."
                            ? "bg-[#84cc16] text-white"
                            : "bg-transparent text-black hover:bg-neutral-100"
                        }`}
                        onClick={() => (page !== "..." ? paginate(page) : null)}
                      >
                        {page}
                      </li>
                    ))}
                    {!isLastPage && (
                      <li
                        className="relative block rounded-2xl px-3 py-1.5 text-xl transition-all duration-300 hover:bg-neutral-100"
                        onClick={() =>
                          setCurrentPage((prev) =>
                            Math.min(
                              prev + 1,
                              Math.ceil(contactData.length / itemsPerPage)
                            )
                          )
                        }
                      >
                        <GrFormNext />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Transition.Root show={currentModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => setCurrentModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Select Headers to Export
                        </Dialog.Title>
                        <div className="grid grid-cols-2 gap-4 mt-4">
                          {headers.map((header, index) => (
                            <div
                              key={index}
                              className="flex items-center capitalize"
                            >
                              <input
                                type="checkbox"
                                id={`header-${index}`}
                                className="mr-2 rounded-md focus:ring-green-500 text-green-600"
                                checked={checkedHeaders.includes(header)}
                                onChange={() => handleHeaderChange(header)}
                              />
                              <label htmlFor={`header-${index}`}>
                                {header}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-[#16a34a] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto  "
                      onClick={finalExport}
                    >
                      Export
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setCurrentModalOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default ViewContact;
