import React, { useState, Fragment, useRef } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { RiFileExcel2Fill } from "react-icons/ri";
import * as XLSX from "xlsx";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { AiFillCheckCircle } from "react-icons/ai";
import SpinningIcon from "./icon/SpinningIcon";
import { GoAlertFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { TbHandClick } from "react-icons/tb";
import { BsInputCursor } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import Breadcrumbs1 from "./breadcrumbs1";
import { format } from 'date-fns';
function BulkUpload() {
  const navigate = useNavigate();

  const headerDataArray = [
    "List_Type",
    "First_Name",
    "Last_Name",
    "Email",
    "Company_Name",
    "Job_Title",
    "Phone_Number",
    "City",
    "State",
    "Country",
    "Industry",
    "Database_Source",
    "Lead_Status",
    "LinkedIN_URL",
    "Remarks",
    "Unsubscribed",
    "Unsubscribed_Date",
    "Bounced",
    "Bounced_Date",
    "Bounced_Reason",
  ];

  function exportToExcel() {
    const data = [headerDataArray]; // Wrap the headerDataArray in an outer array to make it the first row
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data Sheet");

    // Generate a unique file name
    const fileName = "DBMS-Template" + new Date().getTime() + ".xlsx";

    XLSX.writeFile(wb, fileName);
  }

  // onchange states
  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);

  // submit state
  const [excelData, setExcelData] = useState(null);

  //model
  const [currentModalOpen, setCurrentModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  const [selectedRadio, setSelectedRadio] = useState("insertRadio");

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.id);
  };

  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const updateUploadProgress = (percentage) => {
    setUploadProgress(percentage);
  };

  const [uploadReport, setUploadReport] = useState("");
  const [uploadReportData, setUploadReportData] = useState("");

  const [emailListName, setEmailListName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [GroupNamesDataArray, setGroupNamesDataArray] = useState([]);

  const handleButtonClick = () => {
    setShowDropdown(!showDropdown);
    setDropdownOpen(true);
  };

  const handleDropdownButtonClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleGenerateReport = () => {
    generateErrorReport(uploadReportData, emailListName);
  };

  const generateErrorReport = (uploadReportData, commonReason) => {
    // Add a header and combine emails and reasons into a single array of arrays
    const data = [
      ["Reason", "Email"],
      ...uploadReportData.map((email) => [commonReason, email]),
    ];

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Error Report");

    // Save the workbook as a file
    XLSX.writeFile(wb, "error_report" + new Date().getTime() + ".xlsx");
  };

  const handleFileButtonClick = async () => {
    setCurrentModalOpen(true);

    const GroupNamesArray = [];
    try {
      const groupNameResponse = await axios.get(
        `https://dbms.buzz-nation.com/phpApi/getGroupData.php`
      );
      if (groupNameResponse.status === 200) {
        const groupNameResponseData = groupNameResponse.data.data;
        const groupNameValues = groupNameResponseData.map(
          (item) => item.group_name
        );
        GroupNamesArray.push(...groupNameValues);
      } else if (groupNameResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        setUploadReport("An Error Occurred");
      }
    } catch (error) {
      setUploadReport("An Error Occurred");
    }

    // Set the array in state
    setGroupNamesDataArray(GroupNamesArray);
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleOptionClick = (selectedGroupName) => {
    setGroupName(selectedGroupName);
    setDropdownOpen(false); // Close the dropdown after selecting an option
  };

  const handleFile = (e) => {
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(e.target.result);
        };
      } else {
        alert("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      alert("Please select your file");
    }
  };

  const handleFileSubmit = async () => {
    if (excelFile !== null) {
      setUploadInProgress(true);
      setCurrentModalOpen(false);
      setUploadProgress(5);
      setUploadReport("");
      setUploadReportData("");
      setEmailListName("");
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);

      setUploadProgress(5);
      let allEmailsValid = true;
      let noDuplicateInFile = true;

      const invalidEmailArray = [];

      data.forEach((row) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(row.Email)) {
          invalidEmailArray.push(row.Email);
        }
      });

      const allEmailArray = [];
      const emailFrequency = {};

      data.forEach((row) => {
        allEmailArray.push(row.Email);
      });
      allEmailArray.forEach((email) => {
        emailFrequency[email] = (emailFrequency[email] || 0) + 1;
      });

      const duplicateEmails = Object.keys(emailFrequency).filter(
        (email) => emailFrequency[email] > 1
      );
     
      if (invalidEmailArray.length > 0) {
        allEmailsValid = false;
      }

      if (duplicateEmails.length > 0) {
        noDuplicateInFile = false;
      }
      if (allEmailsValid) {
        setUploadProgress(10);
        if (noDuplicateInFile) {
          setUploadProgress(15);
          const apiResponseDataArray = [];
          try {
            const emailResponse = await axios.get(
              `https://dbms.buzz-nation.com/phpApi/getClientEmail.php`
            );
            if (emailResponse.status === 200) {
              const emailResponseData = emailResponse.data.res;
              const emailValues = emailResponseData.map((item) => item.email);
              apiResponseDataArray.push(...emailValues);
            } else if (emailResponse.status === 400) {
            } else {
              // setUploadReport("An Error Occured");
            }
          } catch (error) {
          }
          setUploadProgress(45);
          if (selectedRadio === "insertRadio") {
            let noDuplicate = true;
            const duplicateEmailArray = [];

            data.forEach((row) => {
              if (apiResponseDataArray.includes(row.Email)) {
                duplicateEmailArray.push(row.Email);
                noDuplicate = false;
              }
            });
            if (noDuplicate) {
              setUploadProgress(60);
              const apiPromises = data.map(async (row) => {
                try {
                  row.groupName = groupName;
                  const response = await axios.post(
                    "https://dbms.buzz-nation.com/phpApi/insertDataApi.php",
                    row,
                    {
                      withCredentials: true,
                    }
                  );

                  if (response.status === 200) {
                    return true; // Row inserted successfully
                  } else {
                    return false; // Row insertion failed
                  }
                } catch (error) {
                  setUploadReport("File Upload Failed");
                  return false; // Row insertion failed
                }
              });

              const results = await Promise.all(apiPromises);

              if (results.every((result) => result)) {
                setUploadProgress(100);
                // All rows were inserted successfully
                setUploadReport("File Upload Successful");
                setExcelFile(null);
                setTypeError(null);
                setGroupName("");
              } else {
                // At least one row insertion failed
                setUploadReport("File Upload Failed");
              }
            } else {
              setUploadReport(
                "Duplicate emails found. Please correct the data"
              );
              setUploadReportData(duplicateEmailArray);
              setEmailListName("Duplicate Emails");
              setGroupName("");
            }
          } else if (selectedRadio === "updateRadio") {
            setUploadProgress(60);
            let noUnique = true;
            const UniqueEmailArray = [];
            data.forEach((row) => {
              if (!apiResponseDataArray.includes(row.Email)) {
                UniqueEmailArray.push(row.Email);
                noUnique = false;
              }
            });

            if (noUnique) {
              const apiPromises = data.map(async (row) => {
                try {
                  row.groupName = groupName;
                  const response = await axios.post(
                    "https://dbms.buzz-nation.com/phpApi/updateDataApi.php",
                    row,
                    {
                      withCredentials: true,
                    }
                  );

                  if (response.status === 200) {
                    return true; // Row inserted successfully
                  } else {
                    setGroupName("");
                    return false; // Row insertion failed
                  }
                } catch (error) {
                  setUploadReport("File Upload Failed");
                  setGroupName("");
                  return false; // Row insertion failed
                }
              });

              const results = await Promise.all(apiPromises);

              if (results.every((result) => result)) {
                setUploadProgress(100);
                // All rows were inserted successfully
                setUploadReport("File Upload Successful");
                setExcelFile(null);
                setTypeError(null);
                setGroupName("");
              } else {
                setUploadReport("File Upload Failed");
              }
            } else {
              setUploadReport("Unique Record found. Kindly check your file");
              setUploadReportData(UniqueEmailArray);
              setEmailListName("New Emails");
              setGroupName("");
            }
          }
        } else {
          setUploadReport("Duplicate Emails Present in your file");
          setUploadReportData(duplicateEmails);
          setEmailListName("Duplicate Emails");
        }
        // Wait for all promises to resolve
      } else {
        setUploadReport(
          "Invalid emails found. No Data Imported, Please check the emails"
        );
        setUploadReportData(invalidEmailArray);
        setEmailListName("Invalid Email Format");
      }
      setExcelData(data);
    }
  };

  const handleViewAllContactButtonClick = () => {
    navigate("/view_all_contact");
  };

  return (
    <div className="bg-white">
      <Navbar />
      <Sidebar />
      <div className="ml-[120px] mt-4">
        <div>
          <Breadcrumbs1 level1={uploadInProgress ? "Upload Summary" : "Bulk Upload"} level1Path='/bulk_upload' />
        </div>
        <div className="text-xl font-semibold">
          <span> {uploadInProgress ? "Upload Summary" : "Bulk Upload"}</span>
        </div>
        <div className="mt-4 pr-8 mr-10">
          <div className="h-[420px] w-full border-dashed border-[1.5px] rounded-lg">
            {uploadInProgress ? (
              <div>
                <div className="flex flex-row mt-12 justify-center">
                  <div className="space-y-4">
                    {uploadProgress === 100 ? (
                      <AiFillCheckCircle fontSize="5em" color="#16a34a" />
                    ) : uploadProgress < 100 && uploadReportData ? (
                      <GoAlertFill fontSize="5em" color="#ff0000" />
                    ) : (
                      <SpinningIcon />
                    )}
                  </div>
                </div>
                <div className="flex flex-row mt-12 justify-center">
                  <div className="w-[40%] ">
                    <div className="w-full h-4 mb-2 rounded-full">
                      <div
                        className="h-4 bg-[#0b7764] rounded-full"
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                    <div className="text-lg text-gray-400 ">
                      {uploadProgress}% Complete
                    </div>
                  </div>
                </div>
                <div className="flex flex-row mt-4 justify-center">
                  <div className="text-xl font-semibold text-gray-900 ">
                    <span>{uploadReport}</span>
                  </div>
                </div>

                <div className="flex flex-row mt-4 justify-center">
                  <button
                    className="bg-white text-[#565955] font-semibold py-1 px-4 border border-[#cdcfce]  rounded mr-4"
                    onClick={() => setUploadInProgress(false)}
                  >
                    Back To Uploads
                  </button>
                  {uploadProgress === 100 ? (
                    <button
                      className="bg-[#f0f8f6] text-[#0b7764] font-semibold py-1 px-4 border border-[#0b7764] rounded ml-4 hover:text-white hover:bg-[#16a34a]"
                      onClick={handleViewAllContactButtonClick}
                    >
                      View All Contacts
                    </button>
                  ) : (
                    ""
                  )}
                  {uploadReportData ? (
                    <button
                      className="bg-[#f0f8f6] text-[#0b7764] font-semibold py-1 px-4 border border-[#0b7764] rounded ml-4 hover:text-white hover:bg-[#16a34a]"
                      onClick={handleGenerateReport}
                    >
                      Download Report
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <div>
                <div className="flex flex-row mt-24 justify-center">
                  <div className="space-y-4">
                    <RiFileExcel2Fill color="#16885f" fontSize="5em" />
                  </div>
                </div>
                <div className="flex flex-row justify-center mt-4 text-xl font-semibold text-[#262626]">
                  <span>Import Data From Excel</span>
                </div>
                <div className="flex flex-row justify-center mt-2 text-sm font-normal text-[#595959] ">
                  <span>Only System generated templates are allowed</span>
                </div>
                <div className="flex flex-row justify-center mt-4">
                  <button
                    className="bg-white text-[#565955] font-semibold py-1 px-4 border border-[#cdcfce]  rounded mr-4"
                    onClick={exportToExcel}
                  >
                    Download Template
                  </button>
                  <button
                    className="bg-[#f0f8f6] text-[#0b7764] font-semibold py-1 px-4 border border-[#0b7764] rounded ml-4 hover:text-white hover:bg-[#16a34a]"
                    onClick={handleFileButtonClick}
                  >
                    Select File
                  </button>
                </div>
              </div>
            )}
          </div>
          <Transition.Root show={currentModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              initialFocus={cancelButtonRef}
              onClose={() => setCurrentModalOpen(false)}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#d1fae5] sm:mx-0 sm:h-10 sm:w-10">
                            <RiFileExcel2Fill
                              color="#16885f"
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <Dialog.Title
                              as="h3"
                              className="text-base font-semibold leading-6 text-gray-900"
                            >
                              Upload File
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500 mb-2">
                                Choose an option to upload
                              </p>
                              <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                <input
                                  className="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                                  checked={selectedRadio === "insertRadio"}
                                  onChange={handleRadioChange}
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="insertRadio"
                                />
                                <label
                                  className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                                  htmlFor="insertRadio"
                                >
                                  Insert new Contacts
                                </label>
                              </div>
                              <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                <input
                                  className="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                                  checked={selectedRadio === "updateRadio"}
                                  onChange={handleRadioChange}
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="updateRadio"
                                />
                                <label
                                  className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                                  htmlFor="updateRadio"
                                >
                                  Update Existing Contacts
                                </label>
                              </div>
                              <div className="mt-4">
                                <div className="flex">
                                  {showDropdown ? (
                                    <div className="w-78">
                                      <p className="text-sm text-gray-500 mb-2">
                                        Group Name
                                      </p>

                                      <div className="relative group">
                                        <button
                                          id="dropdown-button"
                                          onClick={handleDropdownButtonClick}
                                          className="inline-flex w-full min-w-[300px] px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                                        >
                                          <span className="mr-2">
                                            {groupName
                                              ? `${groupName}`
                                              : "Select Existing Group"}
                                          </span>
                                        </button>

                                        {isDropdownOpen && (
                                          <div
                                            id="dropdown-menu"
                                            className="absolute z-10 top-10 left-0 mt-2 w-[300px] max-h-[150px] overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-2 space-y-1"
                                          >
                                            <input
                                              id="search-input"
                                              className="block w-full px-4 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
                                              type="text"
                                              placeholder="Search items"
                                              value={searchTerm}
                                              onChange={handleSearchInputChange}
                                            />

                                            {GroupNamesDataArray.filter(
                                              (groupName) =>
                                                groupName
                                                  .toLowerCase()
                                                  .includes(searchTerm)
                                            ).map((groupName, index) => (
                                              <a
                                                key={index}
                                                href="#"
                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                                                onClick={() =>
                                                  handleOptionClick(groupName)
                                                }
                                              >
                                                {groupName}
                                              </a>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="w-78">
                                      <p className="text-sm text-gray-500 mb-2">
                                        Group Name
                                      </p>
                                      <input
                                        type="text"
                                        id="group_name"
                                        className="bg-white border border-neutral-300 text-neutral-700 text-sm font-medium rounded-lg block w-full px-4 py-2 min-w-[300px]"
                                        placeholder="Enter a group Name"
                                        value={groupName}
                                        onChange={(e) =>
                                          setGroupName(e.target.value)
                                        }
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <Tippy
                                      content={
                                        <span className="text-[8pt] p-1 rounded bg-white border border-gray-300 text-black">
                                          {showDropdown
                                            ? "Create a New Group"
                                            : "Select Existing Group"}
                                        </span>
                                      }
                                      arrow={true}
                                      placement="bottom"
                                    >
                                      <button
                                        className="bg-white rounded-md px-2 pt-2 mt-7 pb-1 ml-2 border border-1 border-neutral-200"
                                        onClick={handleButtonClick}
                                      >
                                        {showDropdown ? (
                                          <BsInputCursor
                                            color="#0b7764"
                                            fontSize="1.5em"
                                          />
                                        ) : (
                                          <TbHandClick
                                            color="#0b7764"
                                            fontSize="1.5em"
                                          />
                                        )}
                                      </button>
                                    </Tippy>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4">
                              <p className="text-sm text-gray-500 mb-2">
                                Select File
                              </p>
                              <div className="mt-2">
                                <div className="mb-3">
                                  <input
                                    className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"
                                    type="file"
                                    id="formFile"
                                    onChange={handleFile}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-[#16a34a] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto  "
                          onClick={handleFileSubmit}
                        >
                          Upload File
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => setCurrentModalOpen(false)}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    </div>
  );
}

export default BulkUpload;
