import React, { useState, Fragment, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useParams } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import axios from "axios";
import { MdDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";
import Breadcrumbs1 from "./breadcrumbs1";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { MdEdit } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import { IoMdAttach } from "react-icons/io";
function ContactDetails() {
  const { page, sourceId } = useParams();
  const [activeTab, setActiveTab] = useState("ContactDetails");
  const [groupNamesDataArray, setGroupNamesDataArray] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [unsubscribedDate, setUnsubscribedDate] = useState("");
  const [bouncedDate, setBouncedDate] = useState("");
  //email Status
  const [emailStatusDataArray, setEmailStatusDataArray] = useState([]);
  const [emailStatusForm, setEmailStatusForm] = useState(false);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [isBounced, setIsBounced] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isDelivered, setIsDelivered] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [emailSentDate, setEmailSentDate] = useState(new Date());
  const [emailUnsubscribedDate, setEmailUnsubscribedDate] = useState(
    new Date()
  );
  const [emailBouncedDate, setEmailBouncedDate] = useState(new Date());
  //Edit
  // const [selectedRowData, setSelectedRowData] = useState([]);
  const [isEditEmailModalOpen, setIsEditEmailModalOpen] = useState(false);
  const [editEmailSentDate, setEditEmailSentDate] = useState(new Date());
  const [isEditUnsubscribed, setIsEditUnsubscribed] = useState("No");
  const [isEditBounced, setIsEditBounced] = useState("No");
  const [isEditClicked, setIsEditClicked] = useState("No");
  const [isEditDelivered, setIsEditDelivered] = useState("No");
  const [isEditOpened, setIsEditOpened] = useState("No");
  const [emailEditUnsubscribedDate, setEmailEditUnsubscribedDate] = useState(
    new Date()
  );
  const [emailEditBouncedDate, setEmailEditBouncedDate] = useState(new Date());
  //event
  const [eventDataArray, SetEventDataArray] = useState([]);
  const [eventForm, setEventForm] = useState(false);
  const [eventDate, setEventDate] = useState(new Date());
  const [eventEditForm, setEventEditForm] = useState(false);
  const [eventEditDate, setEventEditDate] = useState(new Date());
  const [selectedEventRowData, setSelectedEventRowData] = useState([]);
  const [documentNotUploaded, setDocumentNotUploaded] = useState(true);
  const [documentName, setDocumentName] = useState("");

  //datePicker

  const [formData, setFormData] = useState({
    // Initialize the form fields with empty values
    id: "",
    list_type: "",
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    job_title: "",
    phone_number: "",
    city: "",
    state: "",
    country: "",
    industry: "",
    database_type: "",
    status: "",
    unsubscribed: "No",
    unsubscribed_date: "", // Assuming a default value for the select field
    bounced: "No",
    bounced_reason: "",
    bounced_date: "", // Assuming a default value for the select field
  });

  const [emailFormData, setEmailFormData] = useState({
    // Initialize the form fields with empty values
    Contact_Id: "",
    Email_Name: "",
    Email_Sent_Date: "",
    Unsubscribed: "No",
    Unsubscribed_Date: "",
    Bounced: "No",
    Bounced_Reason: "",
    Bounced_Date: "",
    Delivered: "No",
    Clicked: "No",
    Opened: "No",
  });

  const [editEmailFormData, setEditEmailFormData] = useState({
    // Initialize the form fields with empty values
    Contact_Id: "",
    email_name: "",
    email_sent_date: "",
    unsubscribed: "No",
    unsubscribed_date: "",
    bounced: "No",
    bounced_reason: "",
    bounced_date: "",
    delivered: "No",
    clicked: "No",
    opened: "No",
  });

  const [eventFormData, setEventFormData] = useState({
    Contact_Id: "",
    Event_Name: "",
    Event_Date: "",
  });

  const [eventEditFormData, setEventEditFormData] = useState({
    event_id: "",
    event_name: "",
    event_date: "",
  });

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    getContactData();
    getGroupNames();
    getEmailStatus();
    getEventStatus();
    getDocumentDetails();
  }, [sourceId]);

  const getDocumentDetails = async () => {
    try {
      const response = await axios.get(
        `https://dbms.buzz-nation.com/phpApi/checkDocument.php?sourceId=${sourceId}`
      );
      console.log(response);
      if (response.status == 200) {
        setDocumentNotUploaded(false);
        setDocumentName(response.data.data.doc_name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getContactData = async () => {
    try {
      const response = await axios.get(
        `https://dbms.buzz-nation.com/phpApi/getClientData.php?sourceId=${sourceId}`
      );

      if (response.status == 200) {
        const responseData = response.data.data[0];

        // Check if unsubscribed_date is '1900-01-01'
        if (responseData.unsubscribed_date === "1900-01-01") {
          // Set the current system date to unsubscribedDate
          setUnsubscribedDate(new Date());
        } else {
          // Set the actual value from the response
          setUnsubscribedDate(new Date(responseData.unsubscribed_date));
        }

        if (responseData.bounced_date === "1900-01-01") {
          // Set the current system date to unsubscribedDate
          setBouncedDate(new Date());
        } else {
          // Set the actual value from the response
          setBouncedDate(new Date(responseData.bounced_date));
        }
        setFormData(responseData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getEmailStatus = async () => {
    const emailStatusArray = [];
    try {
      const emailStatusResponse = await axios.get(
        `https://dbms.buzz-nation.com/phpApi/getEmailStatus.php?sourceId=${sourceId}`
      );
      if (emailStatusResponse.status === 200) {
        const emailStatusResponseData = emailStatusResponse.data.data;
        emailStatusArray.push(...emailStatusResponseData);
      } else if (emailStatusResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    }
    setEmailStatusDataArray(emailStatusArray);
  };

  const getGroupNames = async () => {
    const GroupNamesArray = [];
    try {
      const groupNameResponse = await axios.get(
        `https://dbms.buzz-nation.com/phpApi/getGroupData.php?sourceId=${sourceId}`
      );
      if (groupNameResponse.status === 200) {
        const groupNameResponseData = groupNameResponse.data.data;
        GroupNamesArray.push(...groupNameResponseData);
      } else if (groupNameResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    }
    setGroupNamesDataArray(GroupNamesArray);
  };

  const getEventStatus = async () => {
    const eventArray = [];
    try {
      const eventResponse = await axios.get(
        `https://dbms.buzz-nation.com/phpApi/getEventsInfo.php?sourceId=${sourceId}`
      );
      if (eventResponse.status === 200) {
        const eventResponseData = eventResponse.data.data;
        eventArray.push(...eventResponseData);
      } else if (eventResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    }
    SetEventDataArray(eventArray);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleButtonClick = (groupId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton:
          "inline-block ml-2 rounded bg-[#ff0000] px-6 pb-2 pt-2 text-xs font-medium  leading-normal text-white  transition duration-150 ease-in-out ",
        cancelButton:
          "inline-block mr-2 rounded bg-neutral-100 px-6 pb-2 pt-2 text-xs font-medium  leading-normal text-black  transition duration-150 ease-in-out ",
        text: "text-sm",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        text: `Are you sure?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`https://dbms.buzz-nation.com/phpApi/deleteGroupName.php`, groupId)
            .then((response) => {
              if (response.status == 200) {
                getGroupNames();
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    const updatedFormData = {
      ...formData,
      unsubscribed_date: format(unsubscribedDate, "yyyy-MM-dd"),
      bounced_date: format(bouncedDate, "yyyy-MM-dd"),
    };

    axios
      .post(
        "https://dbms.buzz-nation.com/phpApi/singleContactUpdate.php",
        updatedFormData
      )
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            text: "Data Updated Successfully",
            icon: "success",
          });
          getContactData();
        }
        setEditMode(false);
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };
  const handleEditModeToggle = () => {
    setEditMode(!editMode); // Toggle the edit mode
  };

  const openEmailStatusForm = () => {
    setEmailStatusForm(true); // Toggle the edit mode
  };

  const cancelButtonRef = useRef(null);

  const handleEmailStatusDeleteClick = (emailStatusId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton:
          "inline-block ml-2 rounded bg-[#ff0000] px-6 pb-2 pt-2 text-xs font-medium  leading-normal text-white  transition duration-150 ease-in-out ",
        cancelButton:
          "inline-block mr-2 rounded bg-neutral-100 px-6 pb-2 pt-2 text-xs font-medium  leading-normal text-black  transition duration-150 ease-in-out ",
        text: "text-sm",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        text: `Are you sure?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .post(
              `https://dbms.buzz-nation.com/phpApi/deleteEmailStatus.php`,
              emailStatusId
            )
            .then((response) => {
              if (response.status == 200) {
                getEmailStatus();
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
  };

  const handleUnsubscribedChange = (e) => {
    const value = e.target.value;
    setIsUnsubscribed(value === "Yes");
  };

  const handleBouncedChange = (e) => {
    const value = e.target.value;
    setIsBounced(value === "Yes");
  };

  const handleDeliveredChange = (e) => {
    const value = e.target.value;
    setIsDelivered(value === "Yes");
  };

  const handleClickedChange = (e) => {
    const value = e.target.value;
    setIsClicked(value === "Yes");
  };

  const handleOpenedChange = (e) => {
    const value = e.target.value;
    setIsOpened(value === "Yes");
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();

    if (!emailFormData.Email_Name.trim()) {
      Swal.fire({
        text: "Email Name is Required",
        icon: "warning",
      });
      return;
    }

    const updatedEmailFormData = {
      ...emailFormData,
      Contact_Id: sourceId,
      Unsubscribed_Date: format(emailUnsubscribedDate, "yyyy-MM-dd"),
      Bounced_Date: format(emailBouncedDate, "yyyy-MM-dd"),
      Email_Sent_Date: format(emailSentDate, "yyyy-MM-dd"),
      Bounced: isBounced ? "Yes" : "No",
      Unsubscribed: isUnsubscribed ? "Yes" : "No",
      Opened: isOpened ? "Yes" : "No",
      Delivered: isDelivered ? "Yes" : "No",
      Clicked: isClicked ? "Yes" : "No",
    };

    axios
      .post(
        "https://dbms.buzz-nation.com/phpApi/insertSingleEmailStatusData.php",
        updatedEmailFormData
      )
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            text: "Data Inserted Successfully",
            icon: "success",
          });
          e.target.reset();
          getEmailStatus();
          setEmailStatusForm(false);
        }
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleEditEmailStatus = (emailData) => {
    if (emailData.email_sent_date === "1900-01-01") {
      // Set the current system date to unsubscribedDate
      setEditEmailSentDate(new Date());
    } else {
      // Set the actual value from the response
      setEditEmailSentDate(new Date(emailData.email_sent_date));
    }

    if (emailData.unsubscribed_date === "1900-01-01") {
      // Set the current system date to unsubscribedDate
      setEmailEditUnsubscribedDate(new Date());
    } else {
      // Set the actual value from the response
      setEmailEditUnsubscribedDate(new Date(emailData.unsubscribed_date));
    }

    if (emailData.bounced_date === "1900-01-01") {
      // Set the current system date to unsubscribedDate
      setEmailEditBouncedDate(new Date());
    } else {
      // Set the actual value from the response
      setEmailEditBouncedDate(new Date(emailData.bounced_date));
    }

    setIsEditUnsubscribed(emailData.unsubscribed);
    setIsEditBounced(emailData.bounced);
    setIsEditOpened(emailData.opened);
    setIsEditDelivered(emailData.delivered);
    setIsEditClicked(emailData.clicked);

    setEditEmailFormData(emailData);
    setIsEditEmailModalOpen(true);
  };

  const handleModalClose = () => {
    setIsEditEmailModalOpen(false);
    // setSelectedRowData([]);
  };

  const handleEditUnsubscribedChange = (e) => {
    const value = e.target.value;
    setIsEditUnsubscribed(value);
  };

  const handleEditBouncedChange = (e) => {
    const value = e.target.value;
    setIsEditBounced(value);
  };

  const handleEditDeliveredChange = (e) => {
    const value = e.target.value;
    setIsEditDelivered(value);
  };

  const handleEditOpenedChange = (e) => {
    const value = e.target.value;
    setIsEditOpened(value);
  };

  const handleEditClickedChange = (e) => {
    const value = e.target.value;
    setIsEditClicked(value);
  };

  const openEventForm = () => {
    setEventForm(true);
  };

  const handleEventInfoSubmit = (e) => {
    e.preventDefault();

    if (!eventFormData.Event_Name.trim()) {
      Swal.fire({
        text: "Event/Exhibition Name is Required",
        icon: "warning",
      });
      return;
    }

    const updatedEventFormData = {
      ...eventFormData,
      Contact_Id: sourceId,
      Event_Date: format(eventDate, "yyyy-MM-dd"),
    };

    axios
      .post(
        "https://dbms.buzz-nation.com/phpApi/insertSingleEventStatusData.php",
        updatedEventFormData
      )
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            text: "Data Inserted Successfully",
            icon: "success",
          });
          e.target.reset();
          getEventStatus();
          setEventForm(false);
        }
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });

    e.target.reset();
  };

  const handleEventChange = (e) => {
    const { name, value } = e.target;
    setEventFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleEventEditChange = (e) => {
    const { name, value } = e.target;
    setEventEditFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setSelectedEventRowData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleEditEvent = (event) => {
    if (event.event_date === "1900-01-01") {
      setEditEmailSentDate(new Date());
    } else {
      setEventEditDate(new Date(event.event_date));
    }

    setSelectedEventRowData(event);
    setEventEditFormData(event);
    setEventEditForm(true);
  };

  const handleEventEditSubmit = (e) => {
    e.preventDefault();

    const updatedEventEditFormData = {
      ...eventEditFormData,
      event_id: selectedEventRowData.id,
      event_date: format(eventEditDate, "yyyy-MM-dd"),
    };

    console.log(updatedEventEditFormData);

    if (!updatedEventEditFormData.event_name.trim()) {
      Swal.fire({
        text: "Event/Exhibition Name is Required",
        icon: "warning",
      });
      return;
    }

    axios
      .post(
        "https://dbms.buzz-nation.com/phpApi/updateSingleEventStatusData.php",
        updatedEventEditFormData
      )
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            text: "Data Updated Successfully",
            icon: "success",
          });
          e.target.reset();
          getEventStatus();
          setEventEditForm(false);
        }
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };

  const handleEditEmailSubmit = async (e) => {
    e.preventDefault();

    if (!editEmailFormData.email_name.trim()) {
      Swal.fire({
        text: "Email Name is Required",
        icon: "warning",
      });
      return;
    }

    const updatedEmailFormData = {
      ...editEmailFormData,
      Contact_Id: sourceId,
      unsubscribed_date: format(emailEditUnsubscribedDate, "yyyy-MM-dd"),
      bounced_date: format(emailEditBouncedDate, "yyyy-MM-dd"),
      email_sent_date: format(editEmailSentDate, "yyyy-MM-dd"),
      bounced: isEditBounced,
      unsubscribed: isEditUnsubscribed,
      opened: isEditOpened,
      delivered: isEditDelivered,
      clicked: isEditClicked,
    };

    try {
      const updateResponse = await axios.post(
        "https://dbms.buzz-nation.com/phpApi/updateSingleEmailStatusData.php",
        updatedEmailFormData
      );
      if (updateResponse.status == 200) {
        Swal.fire({
          text: "Data Updated Successfully",
          icon: "success",
        });
        getEmailStatus();
        setIsEditEmailModalOpen(false);
      }
    } catch (error) {
      console.error("Error updating form data:", error);
    }
  };
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditEmailFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();

    const fileInput = e.target.querySelector("#formFile");
    const file = fileInput.files[0];

    // Check if a file is selected
    if (!file) {
      Swal.fire({
        text: "Kindly Select a file",
        icon: "warning",
      });
      return;
    }

    // Check file size (in bytes)
    const maxSizeInBytes = 1024 * 1024; // 1MB
    if (file.size > maxSizeInBytes) {
      Swal.fire({
        text: "File size exceeds the maximum allowed size (1MB)",
        icon: "warning",
      });
      fileInput.value = "";
      return;
    }

    // Get file type
    const allowedFileTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (!allowedFileTypes.includes(file.type)) {
      Swal.fire({
        title: "Invalid file type",
        text: "Allowed File are: Image(png/jpeg/jpg),PDF,PPT,Excel(csv,xlsx)",
        icon: "warning",
      });
      fileInput.value = "";
      return;
    }

    const formData = new FormData();
    formData.append("formFile", file);
    formData.append("contactId", sourceId);

    try {
      // Make a POST request using Axios
      const response = await axios.post(
        "https://dbms.buzz-nation.com/phpApi/uploadDocument.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status == 200) {
        Swal.fire({
          text: "File Uploaded Successfully",
          icon: "success",
        });
        getDocumentDetails();
      } else {
        Swal.fire({
          text: "Error Uploading File",
          icon: "error",
        });
      }
    } catch (error) {
      // Handle errors
      Swal.fire({
        text: "Error Uploading File",
        icon: "error",
      });
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton:
          "inline-block ml-2 rounded bg-[#ff0000] px-6 pb-2 pt-2 text-xs font-medium  leading-normal text-white  transition duration-150 ease-in-out ",
        cancelButton:
          "inline-block mr-2 rounded bg-neutral-100 px-6 pb-2 pt-2 text-xs font-medium  leading-normal text-black  transition duration-150 ease-in-out ",
        text: "text-sm",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        text: `Are you sure to remove this file?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Remove File!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axios.get(
              `https://dbms.buzz-nation.com/phpApi/removeDocument.php?fileName=${documentName}`
            );
            console.log(response);
            if (response.status == 200) {
              setDocumentNotUploaded(true);
              setDocumentName("");
            }
          } catch (error) {
            console.log(error);
          }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
  };

  return (
    <div className="bg-white">
      <Navbar />
      <Sidebar />
      <div className="ml-[120px] mt-4 mr-[40px]">
        <div>
          <Breadcrumbs1
            level1={page == "view_all_contact" ? "View All Contact" : "Filter"}
            level1Path={`/${page}`}
            level2="Contact Information"
            level2Path={`/details/${page}/${sourceId}`}
          />
        </div>
        <div className="flex">
          <div className="text-xl mt-[10px] font-semibold">
            <span>Contact Information</span>
          </div>
          {activeTab === "ContactDetails" && (
            <div className="flex ml-auto">
              <div className="flex items-center mr-4 gap-x-6">
                <button
                  type="button"
                  onClick={handleEditModeToggle}
                  className="text-sm font-semibold rounded-md text-white bg-[#57534E] px-3 py-2 border border-1 border-black-600"
                >
                  {editMode ? "Cancel" : "Edit"}
                </button>
                {editMode && (
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="rounded-md bg-[#16a34a] px-3 py-2 text-sm font-semibold text-white shadow-sm"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          )}
          {activeTab === "EmailDetails" && (
            <div className="flex ml-auto">
              <div className="flex items-center mr-4 gap-x-6">
                <button
                  type="button"
                  onClick={openEmailStatusForm}
                  className="text-sm font-semibold rounded-md text-white bg-[#57534E] px-3 py-2 border border-1 border-black-600"
                >
                  Add Email Details
                </button>
              </div>
            </div>
          )}
          {activeTab === "Event&ExhibitionsDetails" && (
            <div className="flex ml-auto">
              <div className="flex items-center mr-4 gap-x-6">
                <button
                  type="button"
                  onClick={openEventForm}
                  className="text-sm font-semibold rounded-md text-white bg-[#57534E] px-3 py-2 border border-1 border-black-600"
                >
                  Add
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="mt-4">
          <div className="flex space-x-4">
            <button
              className={`py-2 px-4 border-b-2 ${
                activeTab === "ContactDetails"
                  ? "border-[#16a34a]"
                  : "border-transparent"
              }`}
              onClick={() => handleTabClick("ContactDetails")}
            >
              Contact Details
            </button>
            <button
              className={`py-2 px-4 border-b-2 ${
                activeTab === "Event&ExhibitionsDetails"
                  ? "border-[#16a34a]"
                  : "border-transparent"
              }`}
              onClick={() => handleTabClick("Event&ExhibitionsDetails")}
            >
              Event And Exhibitions Details
            </button>
            <button
              className={`py-2 px-4 border-b-2 ${
                activeTab === "EmailDetails"
                  ? "border-[#16a34a]"
                  : "border-transparent"
              }`}
              onClick={() => handleTabClick("EmailDetails")}
            >
              Email Details
            </button>
            <button
              className={`py-2 px-4 border-b-2 ${
                activeTab === "ContactGroupDetails"
                  ? "border-[#16a34a]"
                  : "border-transparent"
              }`}
              onClick={() => handleTabClick("ContactGroupDetails")}
            >
              Contact Group Details
            </button>
          </div>

          {activeTab === "ContactDetails" && (
            <div>
              <form>
                <div className="space-y-12">
                  <div className="border-b border-gray-900/10 pb-8">
                    <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-4">
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          List Type
                        </label>
                        <div className="mt-2">
                          <input type="hidden" value={formData.id} name="id" />
                        </div>
                        <div className="mt-2">
                          <input
                            type="text"
                            value={formData.list_type}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            name="list_type"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          First Name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            value={formData.first_name}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            name="first_name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Last Name
                        </label>
                        <div className="mt-2">
                          <input
                            value={formData.last_name}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            name="last_name"
                            type="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Email address
                        </label>
                        <div className="mt-2">
                          <input
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            type="email"
                            autoComplete="email"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Company Name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            value={formData.company_name}
                            disabled={!editMode}
                            onChange={handleInputChange}
                            name="company_name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Job Title
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            value={formData.job_title}
                            disabled={!editMode}
                            onChange={handleInputChange}
                            name="job_title"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Phone Number
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            value={formData.phone_number}
                            disabled={!editMode}
                            onChange={handleInputChange}
                            name="phone_number"
                            maxLength={10}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          City
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            value={formData.city}
                            disabled={!editMode}
                            onChange={handleInputChange}
                            name="city"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          State
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            value={formData.state}
                            disabled={!editMode}
                            onChange={handleInputChange}
                            name="state"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Country
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            value={formData.country}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            name="country"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Industry
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            value={formData.industry}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            name="industry"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Database Source
                        </label>
                        <div className="mt-2">
                          <input
                            value={formData.database_type}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            type="text"
                            name="database_type"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Lead Status
                        </label>
                        <div className="mt-2">
                          <input
                            value={formData.status}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            type="text"
                            name="status"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Linkedin URL
                        </label>
                        <div className="mt-2">
                          <input
                            value={formData.linkedin_url}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            type="text"
                            name="linkedin_url"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label
                          htmlFor="unsubscribed"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Unsubscribed
                        </label>
                        <div className="mt-2">
                          <select
                            value={
                              formData.unsubscribed == "Yes" ? "Yes" : "No"
                            }
                            onChange={handleInputChange}
                            disabled={!editMode}
                            name="unsubscribed"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:max-w-xs sm:text-sm sm:leading-6"
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                      {formData.unsubscribed === "Yes" && (
                        <div className="sm:col-span-1">
                          <label
                            htmlFor="unsubscribed"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Unsubscribed Date
                          </label>
                          <div className="mt-2">
                            <DatePicker
                              selected={unsubscribedDate}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                              onChange={(date) => setUnsubscribedDate(date)}
                              dateFormat="dd-MM-yyyy"
                              name="unsubscribed_date"
                              disabled={!editMode}
                            />
                            {/* <div className="mt-2">
                              <input
                                value={new Date(
                                  formData.unsubscribed_date
                                ).toLocaleDateString("en-US")}
                                onChange={handleInputChange}
                                disabled={!editMode}
                                type="text"
                                name="unsubscribed_date"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                              />
                            </div> */}
                          </div>
                        </div>
                      )}
                      <div className="sm:col-span-1">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Bounced
                        </label>
                        <div className="mt-2">
                          <select
                            value={formData.bounced ? "Yes" : "No"}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            name="bounced"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:max-w-xs sm:text-sm sm:leading-6"
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                      {formData.bounced === "Yes" && (
                        <div className="sm:col-span-1">
                          <label
                            htmlFor="unsubscribed"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Bounced Date
                          </label>
                          <div className="mt-2">
                            <DatePicker
                              selected={bouncedDate}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                              onChange={(date) => setBouncedDate(date)}
                              dateFormat="dd-MM-yyyy"
                              name="bounced_date"
                              disabled={!editMode}
                            />
                            {/* <div className="mt-2">
                              <input
                                value={new Date(
                                  formData.bounced_date
                                ).toLocaleDateString("en-US")}
                                onChange={handleInputChange}
                                disabled={!editMode}
                                type="text"
                                name="bounced_date"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                              />
                            </div> */}
                          </div>
                        </div>
                      )}
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Bounced Reason
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            value={formData.bounced_reason}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            name="bounced_reason"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Remarks
                        </label>
                        <div className="mt-2">
                          <textarea
                            name="remarks"
                            onChange={handleInputChange}
                            disabled={!editMode}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                          >
                            {formData.remarks}
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="text-xl mt-[10px] font-semibold">
            <span>Attachment</span>
          </div>
              {documentNotUploaded ? (
                <div>
                  <form
                    encType="multipart/form-data"
                    onSubmit={handleFileUpload}
                  >
                    <div className="flex mt-4">
                      <div className="mr-4">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          <span className="text-red-500">*</span>Upload File
                        </label>
                        <div className="mt-2">
                          <div className="mb-3">
                            <input
                              className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"
                              type="file"
                              id="formFile"
                              name="formFile"
                              disabled={!editMode}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-8">
                        <button
                          type="submit"
                          disabled={!editMode}
                          className={`text-sm font-semibold rounded-md text-white px-3 py-2 border border-1 border-black-600 ${
                            editMode ? "bg-[#16a34a]" : "bg-gray-400"
                          }`}
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="max-w-2xl rounded overflow-hidden border border-[#e0e0e0] mt-2 mb-10 ">
                  <div className="py-4 px-4">
                    <div className="flex items-center justify-between">
                      <div className="flex">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <IoMdAttach
                            className="h-8 w-8 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="pt-2 pl-2">
                          <span>{documentName}</span>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <a
                          href={`https://dbms.buzz-nation.com/phpApi/contactDocuments/${documentName}`}
                          className="mx-2"
                          target="_blank"
                          disabled={!editMode}
                        >
                          <FaDownload className="h-8 w-8 text-green-600" />
                        </a>
                        <a
                          className={`ml-2 ${
                            editMode ? "" : "pointer-events-none"
                          }`}
                          onClick={editMode ? handleDelete : undefined}
                        >
                          <MdDeleteForever
                            className={`h-8 w-8 ${
                              editMode ? "text-red-600" : "text-gray-400"
                            }`}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              </div>
          )}
          {activeTab === "Event&ExhibitionsDetails" && (
            <div className=" mt-4 space-y-12">
              <div className="ml-2 inline-block min-w-[35%] shadow rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 tracking-wider">
                        Event/Exhibition Name
                      </th>
                      <th className="pl-2 pr-8 py-2 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 tracking-wider">
                        Event/Exhibition Date
                      </th>
                      <th className="pl-2 pr-8 py-2 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventDataArray.length > 0 ? (
                      eventDataArray.map((event) => (
                        <tr key={event.id}>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {event.event_name}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {new Date(event.event_date).toLocaleDateString(
                                "en-US"
                              )}
                            </p>
                          </td>
                          <td className="pl-2 pr-8 py-2 border-b border-gray-200 bg-white text-xs text-right">
                            <button
                              className="bg-[#ECFCCB] text-white px-1 py-1 font-bold rounded-full"
                              onClick={() => handleEditEvent(event)}
                            >
                              <MdEdit fontSize="2em" color="#22C55E" />
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center py-2">
                          No Event/Exhibitions Details found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {activeTab === "ContactGroupDetails" && (
            <div className=" mt-4 space-y-12">
              <div className="ml-2 inline-block min-w-[40%] shadow rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 tracking-wider">
                        Group Name
                      </th>
                      <th className="pl-2 pr-8 py-2 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupNamesDataArray.length > 0 ? (
                      groupNamesDataArray.map((group) => (
                        <tr key={group.id}>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {group.group_name}
                            </p>
                          </td>
                          <td className="pl-2 pr-8 py-2 border-b border-gray-200 bg-white text-xs text-right">
                            <button
                              className="bg-[#ECFCCB] text-black px-1 py-1 font-bold rounded-full"
                              onClick={() => handleButtonClick(group.id)}
                            >
                              <MdDeleteForever
                                fontSize="2.5em"
                                color="#ff0000"
                              />
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2" className="text-center py-2">
                          No contact group found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {activeTab === "EmailDetails" && (
            <div className=" mt-4 space-y-12">
              <div className="ml-2 inline-block min-w-[100%] shadow rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 w-[120px]">
                        Email Name
                      </th>
                      <th className="pl-2 pr-8 py-2 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 ">
                        Email Sent Date
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 ">
                        Unsubscribed
                      </th>
                      <th className="pl-2 pr-8 py-2 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 ">
                        Unsubscribed Date
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 ">
                        Bounced
                      </th>
                      <th className="pl-2 pr-8 py-2 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 ">
                        Bounced Date
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 ">
                        Bounced Reason
                      </th>
                      <th className="pl-2 pr-8 py-2 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 ">
                        Delivered
                      </th>
                      <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 ">
                        Opened
                      </th>
                      <th className="pl-2 pr-8 py-2 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 ">
                        Clicked
                      </th>
                      <th className="pl-2 pr-8 py-2 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 ">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {emailStatusDataArray.length > 0 ? (
                      emailStatusDataArray.map((emailData) => (
                        <tr key={emailData.id}>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {emailData.email_name}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {new Date(
                                emailData.email_sent_date
                              ).toLocaleDateString("en-US")}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {emailData.unsubscribed == ""
                                ? "No"
                                : emailData.unsubscribed}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {emailData.unsubscribed_date == "1900-01-01"
                                ? ""
                                : new Date(
                                    emailData.unsubscribed_date
                                  ).toLocaleDateString("en-US")}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {emailData.bounced == ""
                                ? "No"
                                : emailData.bounced}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {emailData.bounced_date == "1900-01-01"
                                ? ""
                                : new Date(
                                    emailData.bounced_date
                                  ).toLocaleDateString("en-US")}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {emailData.bounced_reason}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {emailData.delivered == ""
                                ? "No"
                                : emailData.delivered}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {emailData.opened == "" ? "No" : emailData.opened}
                            </p>
                          </td>
                          <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {emailData.clicked == ""
                                ? "No"
                                : emailData.clicked}
                            </p>
                          </td>
                          <td className="pl-2 pr-2 py-2 border-b border-gray-00 bg-white text-xs">
                            <button
                              className="bg-[#ECFCCB] text-white px-1 py-1 font-bold rounded-full"
                              onClick={() => handleEditEmailStatus(emailData)}
                            >
                              <MdEdit fontSize="2em" color="#22C55E" />
                            </button>
                            {/* <button
                              className="bg-[#ECFCCB] text-black px-1 py-1 font-bold rounded-full ml-2"
                              onClick={() =>
                                handleEmailStatusDeleteClick(emailData.id)
                              }
                            >
                              <MdDeleteForever fontSize="2em" color="#EF4444" />
                            </button> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center py-2">
                          No Email Details found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <Transition.Root show={emailStatusForm} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={() => setEmailStatusForm(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-none rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            Add Email Status
                          </Dialog.Title>
                          <div className="mt-4">
                            <form
                              onSubmit={handleEmailSubmit}
                              encType="multipart/form-data"
                            >
                              <div className="space-y-12">
                                <div className="border-b border-gray-900/10 pb-8">
                                  <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-3">
                                    <div className="sm:col-span-1">
                                      <div>
                                        <input
                                          type="hidden"
                                          value={sourceId}
                                          name="Contact_Id"
                                        />
                                      </div>
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        <span className="text-red-500">*</span>
                                        Email Name
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          name="Email_Name"
                                          value={emailFormData.Email_Name}
                                          onChange={handleChange}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        <span className="text-red-500">*</span>
                                        Email Sent Date
                                      </label>
                                      <div className="mt-2">
                                        <DatePicker
                                          showYearDropdown
                                          selected={emailSentDate}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                          onChange={(date) =>
                                            setEmailSentDate(date)
                                          }
                                          dateFormat="dd/MM/yyyy"
                                          name="Email_Sent_Date"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <label
                                        htmlFor="unsubscribed"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Unsubscribed
                                      </label>
                                      <div className="mt-2">
                                        <select
                                          onChange={handleUnsubscribedChange}
                                          name="Unsubscribed"
                                          defaultValue="No"
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:max-w-xs sm:text-sm sm:leading-6"
                                        >
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                        </select>
                                      </div>
                                    </div>
                                    {isUnsubscribed && (
                                      <div className="sm:col-span-1">
                                        <label
                                          htmlFor="unsubscribed"
                                          className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                          Unsubscribed Date
                                        </label>
                                        <div className="mt-2">
                                          <DatePicker
                                            showYearDropdown
                                            selected={emailUnsubscribedDate}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                            onChange={(date) =>
                                              setEmailUnsubscribedDate(date)
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            name="Unsubscribed_Date"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    <div className="sm:col-span-1">
                                      <label
                                        htmlFor="country"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Bounced
                                      </label>
                                      <div className="mt-2">
                                        <select
                                          onChange={handleBouncedChange}
                                          name="Bounced"
                                          defaultValue="No"
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:max-w-xs sm:text-sm sm:leading-6"
                                        >
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                        </select>
                                      </div>
                                    </div>
                                    {isBounced && (
                                      <div className="sm:col-span-1">
                                        <label
                                          htmlFor="unsubscribed"
                                          className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                          Bounced Date
                                        </label>
                                        <div className="mt-2">
                                          <DatePicker
                                            showYearDropdown
                                            selected={emailBouncedDate}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                            onChange={(date) =>
                                              setEmailBouncedDate(date)
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            name="Bounced_Date"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    <div className="sm:col-span-1">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Bounced Reason
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          name="Bounced_Reason"
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <label
                                        htmlFor="Delivered"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Delivered
                                      </label>
                                      <div className="mt-2">
                                        <select
                                          onChange={handleDeliveredChange}
                                          name="Delivered"
                                          defaultValue="No"
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:max-w-xs sm:text-sm sm:leading-6"
                                        >
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <label
                                        htmlFor="Opened"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Opened
                                      </label>
                                      <div className="mt-2">
                                        <select
                                          onChange={handleOpenedChange}
                                          name="Opened"
                                          defaultValue="No"
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:max-w-xs sm:text-sm sm:leading-6"
                                        >
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <label
                                        htmlFor="Clicked"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Clicked
                                      </label>
                                      <div className="mt-2">
                                        <select
                                          onChange={handleClickedChange}
                                          name="Clicked"
                                          defaultValue="No"
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:max-w-xs sm:text-sm sm:leading-6"
                                        >
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                  type="submit"
                                  className="inline-flex w-full justify-center rounded-md bg-[#16a34a] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto  "
                                >
                                  Submit
                                </button>
                                <button
                                  type="button"
                                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                  onClick={() => setEmailStatusForm(false)}
                                  ref={cancelButtonRef}
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={isEditEmailModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={() => setIsEditEmailModalOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-none rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            Edit Email Status
                          </Dialog.Title>
                          <div className="mt-4">
                            <form
                              onSubmit={handleEditEmailSubmit}
                              encType="multipart/form-data"
                            >
                              <div className="space-y-12">
                                <div className="border-b border-gray-900/10 pb-8">
                                  <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-3">
                                    <div className="sm:col-span-1">
                                      <div>
                                        <input
                                          type="hidden"
                                          value={sourceId}
                                          name="Contact_Id"
                                        />
                                      </div>
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        <span className="text-red-500">*</span>
                                        Email Name
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          name="email_name"
                                          value={editEmailFormData.email_name}
                                          onChange={handleEditChange}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        <span className="text-red-500">*</span>
                                        Email Sent Date
                                      </label>
                                      <div className="mt-2">
                                        <DatePicker
                                          showYearDropdown
                                          selected={editEmailSentDate}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                          onChange={(date) =>
                                            setEditEmailSentDate(date)
                                          }
                                          dateFormat="dd/MM/yyyy"
                                          name="email_sent_date"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <label
                                        htmlFor="unsubscribed"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Unsubscribed
                                      </label>
                                      <div className="mt-2">
                                        <select
                                          onChange={
                                            handleEditUnsubscribedChange
                                          }
                                          name="unsubscribed"
                                          value={isEditUnsubscribed}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:max-w-xs sm:text-sm sm:leading-6"
                                        >
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                        </select>
                                      </div>
                                    </div>
                                    {isEditUnsubscribed == "Yes" && (
                                      <div className="sm:col-span-1">
                                        <label
                                          htmlFor="unsubscribed"
                                          className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                          Unsubscribed Date
                                        </label>
                                        <div className="mt-2">
                                          <DatePicker
                                            showYearDropdown
                                            selected={emailEditUnsubscribedDate}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                            onChange={(date) =>
                                              setEmailEditUnsubscribedDate(date)
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            name="unsubscribed_date"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    <div className="sm:col-span-1">
                                      <label
                                        htmlFor="country"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Bounced
                                      </label>
                                      <div className="mt-2">
                                        <select
                                          onChange={handleEditBouncedChange}
                                          name="bounced"
                                          value={isEditBounced}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:max-w-xs sm:text-sm sm:leading-6"
                                        >
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                        </select>
                                      </div>
                                    </div>
                                    {isEditBounced == "Yes" && (
                                      <div className="sm:col-span-1">
                                        <label
                                          htmlFor="unsubscribed"
                                          className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                          Bounced Date
                                        </label>
                                        <div className="mt-2">
                                          <DatePicker
                                            showYearDropdown
                                            selected={emailEditBouncedDate}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                            onChange={(date) =>
                                              setEmailEditBouncedDate(date)
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            name="bounced_date"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    <div className="sm:col-span-1">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Bounced Reason
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          onChange={handleEditChange}
                                          type="text"
                                          name="bounced_reason"
                                          value={
                                            editEmailFormData.bounced_reason
                                          }
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <label
                                        htmlFor="Delivered"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Delivered
                                      </label>
                                      <div className="mt-2">
                                        <select
                                          onChange={handleEditDeliveredChange}
                                          value={isEditDelivered}
                                          name="delivered"
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:max-w-xs sm:text-sm sm:leading-6"
                                        >
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <label
                                        htmlFor="Opened"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Opened
                                      </label>
                                      <div className="mt-2">
                                        <select
                                          onChange={handleEditOpenedChange}
                                          name="opened"
                                          value={isEditOpened}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:max-w-xs sm:text-sm sm:leading-6"
                                        >
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <label
                                        htmlFor="Clicked"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Clicked
                                      </label>
                                      <div className="mt-2">
                                        <select
                                          onChange={handleEditClickedChange}
                                          name="clicked"
                                          value={isEditClicked}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:max-w-xs sm:text-sm sm:leading-6"
                                        >
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                  type="submit"
                                  className="inline-flex w-full justify-center rounded-md bg-[#16a34a] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto  "
                                >
                                  Update
                                </button>
                                <button
                                  type="button"
                                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                  onClick={handleModalClose}
                                  ref={cancelButtonRef}
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={eventForm} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={() => setEventForm(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-none rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            Add Event & Exhibition Information
                          </Dialog.Title>
                          <div className="mt-4">
                            <form
                              onSubmit={handleEventInfoSubmit}
                              encType="multipart/form-data"
                            >
                              <div className="space-y-12">
                                <div className="border-b border-gray-900/10 pb-8">
                                  <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-3">
                                    <div className="sm:col-span-2">
                                      <div>
                                        <input
                                          type="hidden"
                                          value={sourceId}
                                          name="Contact_Id"
                                        />
                                      </div>
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        <span className="text-red-500">*</span>
                                        Event/Exhibition Name
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          name="Event_Name"
                                          // value={eventFormData.Event_Name}
                                          onChange={handleEventChange}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        <span className="text-red-500">*</span>
                                        Event/Exhibition Date
                                      </label>
                                      <div className="mt-2">
                                        <DatePicker
                                          showYearDropdown
                                          selected={eventDate}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                          onChange={(date) =>
                                            setEventDate(date)
                                          }
                                          dateFormat="dd/MM/yyyy"
                                          name="Event_Date"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                  type="submit"
                                  className="inline-flex w-full justify-center rounded-md bg-[#16a34a] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto  "
                                >
                                  Submit
                                </button>
                                <button
                                  type="button"
                                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                  onClick={() => setEventForm(false)}
                                  ref={cancelButtonRef}
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={eventEditForm} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={() => setEventEditForm(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-none rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            Edit Event & Exhibition Information
                          </Dialog.Title>
                          <div className="mt-4">
                            <form
                              onSubmit={handleEventEditSubmit}
                              encType="multipart/form-data"
                            >
                              <div className="space-y-12">
                                <div className="border-b border-gray-900/10 pb-8">
                                  <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-3">
                                    <div className="sm:col-span-2">
                                      <div>
                                        <input
                                          type="hidden"
                                          value={selectedEventRowData.id}
                                          name="event_id"
                                        />
                                      </div>
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        <span className="text-red-500">*</span>
                                        Event/Exhibition Name
                                      </label>
                                      <div className="mt-2">
                                        <input
                                          type="text"
                                          name="event_name"
                                          value={eventEditFormData.event_name}
                                          onChange={handleEventEditChange}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        <span className="text-red-500">*</span>
                                        Event/Exhibition Date
                                      </label>
                                      <div className="mt-2">
                                        <DatePicker
                                          showYearDropdown
                                          selected={eventEditDate}
                                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
                                          onChange={(date) =>
                                            setEventEditDate(date)
                                          }
                                          dateFormat="dd/MM/yyyy"
                                          name="event_date"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                  type="submit"
                                  className="inline-flex w-full justify-center rounded-md bg-[#16a34a] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto  "
                                >
                                  Update
                                </button>
                                <button
                                  type="button"
                                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                  onClick={() => setEventEditForm(false)}
                                  ref={cancelButtonRef}
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}

export default ContactDetails;
