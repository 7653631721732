import React, { useState, useEffect,useRef } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import axios from "axios";
import Swal from "sweetalert2";
import Breadcrumbs1 from "./breadcrumbs1";
import { ImCross } from "react-icons/im";

const FormStructure = ({
  index,
  onRemove,
  onEmailChange,
  onFileChange,
  onEmailComplete,
}) => (
  <div className="flex mt-2">
    <div className="w-[300px]">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        <span className="text-red-500">*</span>Email Address
      </label>
      <div className="mt-2">
        <input
          type="text"
          name="email"
          onBlur={(e) => onEmailComplete(e.target.value)}
          onChange={(e) => onEmailChange(e.target.value)}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#000000] sm:text-sm sm:leading-6"
        />
      </div>
    </div>
    <div className="mx-4">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        <span className="text-red-500">*</span>Upload File
      </label>
      <div className="mt-2">
        <div className="mb-3">
          <input
            className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"
            type="file"
            id="formFile"
            name="formFile"
            onChange={(e) => onFileChange(e.target.files[0])}
          />
        </div>
      </div>
    </div>
    {index > 0 && (
      <div>
        <a onClick={() => onRemove(index)}>
          <ImCross color="#ff0000" />
        </a>
      </div>
    )}
  </div>
);

function DocumentBulkUpload() {
  const [formCount, setFormCount] = useState(1);
  const [formData, setFormData] = useState([{ email: "", file: null }]);
  const [emails,setEmails] = useState([]);
  const [docUploaded,setDocUploaded] = useState([]);

  useEffect(() => {
    getContactEmail();
    getDocumentUploaded();
  }, []);

  // let apiResponseDataArray = [];
  // let docUploaded = [];
  const getContactEmail = async () => {
    try {
      const emailResponse = await axios.get(
        `https://dbms.buzz-nation.com/phpApi/getClientEmail.php`
      );
      if (emailResponse.status === 200) {
        const emailResponseData = emailResponse.data.res;
        const emailValues = emailResponseData.map((item) => item.email);
        setEmails(emailValues);
      } else if (emailResponse.status === 400) {
      } else {
      }
    } catch (error) {}
  };

  

  const getDocumentUploaded = async () => {
    try {
      const emailResponse = await axios.get(
        `https://dbms.buzz-nation.com/phpApi/getDocumentUploadedEmail.php`
      );
      // console.log(emailResponse);
      if (emailResponse.status === 200) {
        const emailResponseData = emailResponse.data.data;
        console.log(emailResponseData);
        const emailValues = emailResponseData.map((item) => item.email);
        // console.log(emailValues);
        setDocUploaded(emailValues);
      } else if (emailResponse.status === 400) {
      } else {
      }
    } catch (error) {}
  };

  // console.log(docUploaded);

  const handleEmailChange = (index, email) => {
    setFormData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = { ...updatedData[index], email };
      return updatedData;
    });
  };

  const handleFileChange = (index, file) => {
    const maxSizeInBytes = 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      Swal.fire({
        text: "File size exceeds the maximum allowed size (1MB)",
        icon: "warning",
      });
      return;
    }
    const allowedFileTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (!allowedFileTypes.includes(file.type)) {
      Swal.fire({
        title: "Invalid file type",
        text: "Allowed File types are: Image(png/jpeg/jpg), PDF, PPT, Excel(csv,xlsx)",
        icon: "warning",
      });
      return;
    }
    setFormData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = { ...updatedData[index], file };
      return updatedData;
    });
  };

  const formRef = useRef();

  const handleAddForm = () => {
    setFormCount((prevCount) => prevCount + 1);
    setFormData((prevData) => [...prevData, { email: "", file: null }]);
  };

  const handleRemoveForm = (index) => {
    setFormData((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      return updatedData;
    });
    setFormCount((prevCount) => prevCount - 1);
  };

  const checkEmail = (email) => {
    if (email != "") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(email)) {
        Swal.fire({
          text: email + " is invalid Email",
          icon: "warning",
        });
        return;
      }

      if (!emails.includes(email)) {
        Swal.fire({
          text: email + " is not present in our database",
          icon: "warning",
        });
        return;
      }

      if (docUploaded.includes(email)) {
        Swal.fire({
          text: "Document is already uploaded for " + email,
          icon: "warning",
        });
        return;
      }
    }
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    try {
      const uploadPromises = formData.map(async (data, index) => {
        if (data.file) {
          const formDataForIndex = new FormData();
          formDataForIndex.append("file", data.file);
          formDataForIndex.append("email", data.email);
          const response = await axios.post(
            "https://dbms.buzz-nation.com/phpApi/bulkUploadDocument.php",
            formDataForIndex,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }
      });
      await Promise.all(uploadPromises);
      Swal.fire({
        text: "All files uploaded successfully",
        icon: "success",
      });
      setFormData([{ email: "", file: null }]);
      setFormCount(1);
      formRef.current.reset();
    } catch (error) {
      Swal.fire({
        text: "Error Uploading Files",
        icon: "error",
      });
    }
  };

  return (
    <div className="bg-white">
      <Navbar />
      <Sidebar />
      <div className="ml-[120px] mt-4 mr-[40px]">
        <div>
          <Breadcrumbs1
            level1="Upload Documents"
            level1Path="/document_bulk_upload"
          />
        </div>
        <div className="flex items-center">
          <div className="text-xl mt-[10px] font-semibold">
            <span>Upload Documents</span>
          </div>
          <div className="ml-[360px] flex space-x-4">
            <button
              type="button"
              onClick={handleAddForm}
              className="text-sm font-semibold rounded-md text-white bg-[#57534E] px-3 py-2 border border-1 border-black-600"
            >
              Add
            </button>
            <button
              type="submit"
              onClick={handleFileUpload}
              className="text-sm font-semibold rounded-md text-white bg-[#16a34a] px-3 py-2 border border-1 border-black-600"
            >
              Upload
            </button>
          </div>
        </div>

        <div className="">
          <form ref={formRef} id="uploadForm">
            {[...Array(formCount)].map((_, index) => (
              <FormStructure
                key={index}
                index={index}
                onRemove={() => handleRemoveForm(index)}
                onEmailChange={(email) => handleEmailChange(index, email)}
                onFileChange={(file) => handleFileChange(index, file)}
                onEmailComplete={(email) => checkEmail(email)}
              />
            ))}
          </form>
        </div>
      </div>
    </div>
  );
}

export default DocumentBulkUpload;
